import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useMain } from "../hooks/useMain";
import axios from "axios";
import OptionBuySell from "./Trading/OptionBuySell";
import Option from "./Trading/Option";
import OptionPaperOrder from "./OptionPaperOrder";
import arrow from "../Assets/Group 1000005963.svg";
import HelmetBase from "../helmet/HelmetBase";

function Spot() {
  const { isoptionCall, optionCall, userData } = useMain();
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  useEffect(()=>{
    setToggle(false)
  },[optionCall])

  return (
    <>
      <HelmetBase
      title={"Trade Cryptocurrencies at Market Price on crypto24x7 Spot"}
      description={
        "Experience the speed and convenience of spot trading with Crypto24x7. Our platform provides instant execution of trades at market price and a ₹100 cashback bonus to get you started."
      }
    />
      <Navbar />
      {/* <br />
      <br /> */}
      <div style={{ display:"flex" ,justifyContent:"end"}}>
        <div className="stockrates-div" >
          <p className="stockrates-divp">Wallet</p>
          <p>{userData?.wallet?.currency}{" "}{Number(userData?.wallet?.balance||0).toFixed(2)}</p>
        </div>
      </div>


      <Option />
      <br />
      <br />
      <br />
      {isoptionCall && <OptionBuySell />}
      {/* <h1>hiii</h1> */}
      <br /><br /><br />


      <div className="order-accodian">
        <div className="order-accodian-div">
          <div className="order-accodian-div-2">
            <p>Order Mangement</p>
            <img src={arrow} onClick={handleToggle} />
          </div>
          <div>{toggle && <OptionPaperOrder isCalling={toggle} />}</div>
        </div>
      </div>
    <br /><br /><br />
    <br /><br /><br />
      <Footer />
    </>
  );
}

export default Spot;

// <tr key={index}>
// <th scope='row'>{option.symbol}</th>
// <td>{option.symbol}</td>
// <td>P&F - F.a</td>
// <td><button className='call'>Call Buy</button></td>
// <td><button className='put'>Put Buy</button></td>
// <td><button className='strike'>{option.strikePrice}</button></td>
// <td><button className='call'>Call Sell</button></td>
// <td><button className='put'>Put Sell</button></td>
// <td>{option.lastPrice}</td>
// <td>{option.volume}</td>
// </tr>
