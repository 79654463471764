import React from 'react'
import img from '../Assets/image 2 (1).svg'

import google from '../Assets/Google.svg'


function MFACode() {
  return (
    <>
    <>
  <div className='login'>
  <div className='login-left'>
<h1>Superme Logo</h1>
<div  className='login-left-center'>
<img src={img}/>

</div>
</div>
      <div className='login-right'>
      <button className='signup-btn'>Sing Up</button>

<br/>
<br/>
<br/>
<div className='login-form'>
  <h2>MFA code</h2>
  <br/>
  <h3>We just send you a verify code. Check your inbox to get them.</h3>
<br/>
<br/>
<input placeholder='Enter Code' />
<br/>

<br/>
<div className='center'>
<button className='logbtn'>Submit</button>

</div>    
<br/>
 
</div>
      </div>
  </div>


  </>

</>
  )
}

export default MFACode