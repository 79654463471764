import React, { useState, useEffect } from "react";
import { useMain } from "../hooks/useMain";
import ActivePosition from "./Trading/ActivePosition";
import ActiveOptionPosition from "./Trading/ActiveOptionPosition";
import { useOptionWebSocketContext } from "../utils/optionWebSocket";
import OptionPendingOrder from "./Trading/order/OptionPendingOrder";

const OptionPaperOrder = ({isCalling}) => {
    const { getAllOptionOrder, orderCall, optionOrder } = useMain();
    const [activeTab, setActiveTab] = useState(1);
    const [orders, setOrders] = useState([]);
    // const [prices, setPrices] = useState({});
    // const [profits, setProfits] = useState({});
    const { connected, orders:websocketOrders, sendMessage } = useOptionWebSocketContext();
  
    // useEffect(() => {
    //   const fetchOrders = async () => {
    //     if (isCalling) {
    //       const fetchedOrders = await getAllOptionOrder();
    //       setOrders(fetchedOrders?.data);
    //     }
    //   };
    //   fetchOrders();
    // }, [isCalling, orderCall, activeTab]);

    useEffect(()=>{
      if(connected){
        setOrders(optionOrder)
      }
    },[isCalling, connected, websocketOrders, optionOrder])
  
    return (
      <div className="stock-passesall-div">
        <div className="Run-scan-div" style={{ justifyContent: "start" }}>
          <div className="Run-scan">
            <button
              className={`Run-scan-btn ${activeTab === 1 ? "Export" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              Active Position
            </button>
            <button
              className={`Run-scan-btn ${activeTab === 2 ? "Export" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Pending Order
            </button>
            <button
              className={`Run-scan-btn ${activeTab === 3 ? "Export" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Day's History
            </button>
          </div>
        </div>
        {activeTab === 1 && <ActiveOptionPosition isCalling={isCalling} />}
        {activeTab === 2 && ( <OptionPendingOrder orders={orders} sendMessage={sendMessage} />
          // <table className="table">
          //   <thead className="thead-dark">
          //     <tr>
          //       <th scope="col">User ID</th>
          //       <th scope="col">Option</th>
          //       <th scope="col">Order Type</th>
          //       <th scope="col">Strike Prize</th>
          //       <th scope="col">Crypto Symbol</th>
          //       <th scope="col">Amount</th>
          //       <th scope="col">Order Price</th>
          //       {/* <th scope="col">Live Price</th> */}
          //       <th scope="col">Status</th>
          //       <th scope="col">Quantity</th>
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {orders && orders.length > 0 && orders
          //       .filter((order) => order.status === "pending")
          //       .map((order) => (
          //         <tr key={order._id}>
          //           <td>{order?.userId}</td>
          //           <td>{order?.optionType}</td>
          //           <td>{order?.strikePrice}</td>
          //           <td>{order?.orderType}</td>
          //           <td>{order?.cryptoSymbol}</td>
          //           <td>{order?.amount}</td>
          //           <td>{order?.price}</td>
          //           {/* <td>{prices[order.cryptoSymbol] || 'Loading...'}</td> */}
          //           <td>{order?.status}</td>
          //           <td>{order?.qty}</td>
          //         </tr>
          //       ))}
          //   </tbody>
          // </table>
        )}
        {activeTab === 3 && (
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">User ID</th>
                <th scope="col">Option</th>
                <th scope="col">Strike Prize</th>
                <th scope="col">Order Type</th>
                <th scope="col">Crypto Symbol</th>
                <th scope="col">Amount</th>
                <th scope="col">Order Price</th>
                {/* <th scope="col">Live Price</th> */}
                <th scope="col">Status</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.length > 0 && orders
                .filter((order) => order.status === "completed")
                .map((order) => (
                  <tr key={order?._id}>
                    <td>{order?.userId}</td>
                    <td>{order?.optionType?.toUpperCase()}</td>
                    <td>{order?.strikePrice}</td>
                    <td>{order?.orderType}</td>
                    <td>{order?.cryptoSymbol}</td>
                    <td>{order?.amount}</td>
                    <td>{order?.price}</td>
                    {/* <td>{prices[order.cryptoSymbol] || 'Loading...'}</td> */}
                    <td>{order?.status}</td>
                    <td>{order?.qty}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

export default OptionPaperOrder
