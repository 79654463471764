import axios from "axios";
import { headers, jwtHeaders } from "../common/data";
import { scannerBaseUrl as baseUrl } from "../../utils/binanceurl";


export const addDashBoard = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteDashBoard = async (id) => {
    try {
        const res = await axios.delete(`${baseUrl}/dashboard/${id}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllDashBoard = async (visibility) => {
    try {
        let uri = `${baseUrl}/dashboard`
        if(visibility=="private"){
            uri= uri + `?visibility=private`
        }
        const res = await axios.get(uri, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllBothDashBoard = async (visibility) => {
    try {
        let uri = `${baseUrl}/dashboard/get/both`
        const res = await axios.get(uri, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getSingleDashBoard = async (id) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/${id}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const updateDashBoard = async (data, id) => {
    try {
        const res = await axios.put(`${baseUrl}/dashboard/${id}`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getDashBoardByName = async (name) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/byname?name=${name}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const copyDashboard = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard/copy`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const generateTokenApi = async (id) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard/generate/token/${id}`, {},{ headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const addLinkDashboard = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard/share/token`, data,{ headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getLinkDashboard = async (data) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/share/bysubscriber`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const rateDashboard = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard/rating`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getratingbyUserDashboard = async (id) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/rating/check?dashboardId=${id}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}


