import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";

const EducationalInformation = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [formData, setFormData] = useState({
    highestDegreeObtained: "",
    universityCollegeName: "",
    fieldOfStudy: "",
    graduationYear: "",
    additionalCoursesCertifications: "",
  });

  useEffect(() => {
    if (userData && userData.educationalInformation) {
      setFormData(userData.educationalInformation);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUserData({ educationalInformation: formData });
      if (res) {
        handleUser(res.data);
      }
    } catch (error) {
      console.error("Error updating educational information:", error);
    }
  };

  return (
    <div className="tab-content-div">
      <div className="tab-content-innerdiv">
        <div className="tab-content-heading">
          <h3>Educational Information</h3>
          <p>Details about your educational background.</p>
        </div>
        <div className="tab-content-inputs">
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Highest Degree Obtained</label>
              <input
                type="text"
                name="highestDegreeObtained"
                value={formData?.highestDegreeObtained}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>University/College Name</label>
              <input
                type="text"
                name="universityCollegeName"
                value={formData?.universityCollegeName}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Field of Study</label>
              <input
                type="text"
                name="fieldOfStudy"
                value={formData?.fieldOfStudy}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Graduation Year</label>
              <input
                type="number"
                name="graduationYear"
                value={formData?.graduationYear}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Additional Courses/Certifications</label>
              <input
                type="text"
                name="additionalCoursesCertifications"
                value={formData?.additionalCoursesCertifications}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="savechngs" onClick={handleSubmit}>Save Changes</button>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default EducationalInformation;
