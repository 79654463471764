import axios from "axios";
import { jwtHeaders } from "../common/data";
import { userBaseUrl as baseUrl } from "../../utils/binanceurl";

export const chatSend = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/admin/chat`, data)
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}