import React, { useState } from 'react'
import { toast } from 'react-toastify';

const OptionPendingOrder = ({ orders, sendMessage}) => {
    const [updateDetails, setUpdateDetails] = useState({});
    const [editingOrderId, setEditingOrderId] = useState(null);
    const [activeTab, setActiveTab] = useState("pending");
    // console.log(orders);
    const handleCancelOrder = (order) => {
      const {baseAsset, _id:orderId} = order
      if(!orderId || !baseAsset){
        return toast.error("Internal Error Refererse Page.")
      }
      const usertoken = sessionStorage.getItem("user_token");
      sendMessage('updateOrCancelOrder', { usertoken, orderId, action: 'cancel', baseAsset:baseAsset });
    };
  
    const handleStartUpdate = (orderId) => {
      setEditingOrderId(orderId);
      const order = orders.find(o => o._id === orderId);
      setUpdateDetails({
        [orderId]: {
          price: order.price,
          qty: order.qty,
          amount: order.price * order.qty
        }
      });
    };
  
    const handleConfirmUpdate = (order) => {
      const usertoken = sessionStorage.getItem("user_token");
      const {baseAsset, _id:orderId} = order
      if(!orderId || !baseAsset){
        return toast.error("Internal Error Refererse Page.")
      }
      const details = updateDetails[orderId] || {};
      sendMessage('updateOrCancelOrder', { usertoken, orderId, action: 'update', updateDetails: details,baseAsset:baseAsset});
      setEditingOrderId(null); // Reset editing state after confirming update
    };
  
    const handleChange = (orderId, field, value) => {
      setUpdateDetails(prevDetails => {
        const newDetails = {
          ...prevDetails,
          [orderId]: {
            ...prevDetails[orderId],
            [field]: value,
            amount: (field === 'price' ? value : prevDetails[orderId].price) * (field === 'qty' ? value : prevDetails[orderId].qty)
          }
        };
        return newDetails;
      });
    };
  
    return (
      <>
        <div className="tabs">
        <button
          className={`tab ${activeTab === "pending" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("pending");
          }}
        >
          Pending Orders
        </button>
        <button
          className={`tab ${activeTab === "cancelled" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("cancelled");
          }}
        >
          Cancel Orders
        </button>
      </div>
      <br/>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">User ID</th>
            <th scope="col">Order Type</th>
            <th scope="col">Crypto Symbol</th>
            <th scope="col">Amount</th>
            <th scope="col">Order Price</th>
            <th scope="col">Status</th>
            <th scope="col">Quantity</th>
            {activeTab == "pending" && <th scope="col">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {orders && orders.length > 0 && orders
            .filter((order) => order?.status ==activeTab)
            .map((order) => (
              <tr key={order._id}>
                <td>{order.userId}</td>
                <td>{order.orderType}</td>
                <td>{order.cryptoSymbol}</td>
                <td>
                  {editingOrderId === order._id ? (
                    <input
                      type="number"
                      value={updateDetails[order._id]?.amount || order.amount}
                      onChange={(e) => handleChange(order._id, 'amount', parseFloat(e.target.value))}
                      disabled
                    />
                  ) : (
                    order.amount
                  )}
                </td>
                <td>
                  {editingOrderId === order._id ? (
                    <input
                      type="number"
                      value={updateDetails[order._id]?.price || order.price}
                      onChange={(e) => handleChange(order._id, 'price', parseFloat(e.target.value))}
                    />
                  ) : (
                    order.price
                  )}
                </td>
                <td>{order.status}</td>
                <td>
                  {editingOrderId === order._id ? (
                    <input
                      type="number"
                      value={updateDetails[order._id]?.qty || order.qty}
                      onChange={(e) => handleChange(order._id, 'qty', parseFloat(e.target.value))}
                    />
                  ) : (
                    order.qty
                  )}
                </td>
                {activeTab == "pending" && (
                <td>
                  {editingOrderId === order._id ? (
                    <>
                      <button onClick={() => handleConfirmUpdate(order)}>Confirm</button>
                      <button onClick={() => setEditingOrderId(null)}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleCancelOrder(order)} disabled={order.status === "cancelled"}>Cancel</button>
                      <button onClick={() => handleStartUpdate(order._id)} disabled={order.status === "cancelled"}>Update</button>
                    </>
                  )}
                </td>
                 )}
              </tr>
            ))}
        </tbody>
      </table>
      </>
    );
}

export default OptionPendingOrder
