import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { useMain } from "../../hooks/useMain";
import dashboardIcon from "../../Assets/dashboard-svgrepo-com 2.png";
import profile from "../../Assets/Group 34630.png";
import trash from "../../Assets/delete-filled-svgrepo-com 9.png";
import { toast } from "react-toastify";

const AllScanner = () => {
  const [allDashboard, setAllDashboard] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");
  const navigate = useNavigate();
  const { getAllDashBoard, deleteDashBoard, addDashBoard } = useMain();

  const getData = async () => {
    const res = await getAllDashBoard();
    // console.log(res);
    setAllDashboard(res?.data);
  };

  const handleDelete = async (id) => {
    const res = await deleteDashBoard(id);
    getData();
  };

  const handleCreateDashboard = async () => {
    if (!newDashboardName) {
      toast.error("Please enter a dashboard name.");
      return;
    }
    const res = await addDashBoard({ name: newDashboardName });
    if (res?.data) {
      toast.success("Dashboard created successfully!");
      getData(); // Refresh dashboard list
      setShowModal(false); // Close modal
      setNewDashboardName(""); // Clear input
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="creatDashboard">
        <h1>Your Dashboard</h1>
        <button onClick={() => setShowModal(true)}>
          <img src={dashboardIcon} alt="Create Dashboard" />
          Create Dashboard
        </button>
      </div>
      <br />
      <br />
      <div className="yourboard">
        {allDashboard &&
          allDashboard.length > 0 &&
          allDashboard.map((ele) => (
            <div className="dashboard-card" key={ele._id}>
              <img src={profile} alt="Dashboard Profile" />
              <div
                className="dashboard-card-text"
                style={{ cursor: "pointer" }}
              >
                <h3 onClick={() => navigate(`/Dashboard/${ele?._id}`)}>
                  {ele.name}
                </h3>
                <p>
                  Has Query{" "}
                  <img
                    src={trash}
                    alt="Delete"
                    onClick={() => handleDelete(ele?._id)}
                  />
                </p>
              </div>
            </div>
          ))}
      </div>

      {/* Modal for adding a new dashboard */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newDashboardName">
              <Form.Label>Dashboard Name</Form.Label>
              <Form.Control
                type="text"
                value={newDashboardName}
                onChange={(e) => setNewDashboardName(e.target.value)}
                placeholder="Enter dashboard name"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateDashboard}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllScanner;
