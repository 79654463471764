import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";
import { toast } from "react-toastify";
import useWebSocket, { useWebSocketContext } from "../../utils/useWebSocket";
import { binanceUrl } from "../../utils/binanceurl";

const ActivePosition = ({ isCalling }) => {
  const [positions, setPositions] = useState([]);
  const [activePositions, setActivePositions] = useState([]);
  const [inactivePositions, setInactivePositions] = useState([]);
  const [prices, setPrices] = useState({});
  const [profits, setProfits] = useState({});
  const [activeTab, setActiveTab] = useState("active");
  const [totalPnl, setTotalPnl] = useState(0);
  const [squareOffDetails, setSquareOffDetails] = useState({});
  const { getAllPosition, squareOffOrder, handleUser } = useMain();
  const { sendMessage, orders, paperPosition } = useWebSocketContext();
  // console.log(orders);

  useEffect(() => {
    const fetchPositions = async () => {
      if (isCalling) {
        const allPositions = await getAllPosition();
        const active =
          allPositions?.data.filter((pos) => pos.status === "active") || [];
        const inactive =
          allPositions?.data.filter((pos) => pos.status === "inactive") || [];
        setActivePositions(active);
        setInactivePositions(inactive);
        setPositions(active);
      }
    };
    fetchPositions();
  }, [isCalling]);

  useEffect(() => {
    if (paperPosition.length > 0) {
      const active =
        paperPosition?.filter((pos) => pos.status === "active") || [];
      const inactive =
        paperPosition?.filter((pos) => pos.status === "inactive") || [];
      setActivePositions(active);
      setInactivePositions(inactive);
      setPositions(active);
      // console.log(active, "Active", inactive, "inactive");
    }
  }, [paperPosition]);

  useEffect(() => {
    const client = new WebSocket(
      "wss://stream.binance.com:9443/ws/!ticker@arr"
    );
    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      const newPrices = {};
      data.forEach((item) => {
        // newPrices[item.s.replace("USDT", "")] = parseFloat(item.c);
        newPrices[item.s] = parseFloat(item.c);
      });
      setPrices((prevPrices) => ({ ...prevPrices, ...newPrices }));
    };
    return () => {
      client.close();
    };
  }, []);

  useEffect(() => {
    const calculateProfits = () => {
      const newActiveProfits = {};
      const newInactiveProfits = {};
      let totalActivePnl = 0;
      let totalInactivePnl = 0;

      activePositions.forEach((order) => {
        const currentPrice = prices[order.cryptoSymbol];
        if (currentPrice) {
          let profit = 0;
          if (order.orderType === "buy") {
            const buyValue = order.buyavgPrice * order.buyqty;
            const sellValue = currentPrice * order.buyqty;
            profit = sellValue - buyValue;
          } else if (order.orderType === "sell") {
            const sellValue = order.sellavgPrice * order.sellqty;
            const buyValue = currentPrice * order.sellqty;
            profit = sellValue - buyValue;
          }
          newActiveProfits[order._id] = profit;
          totalActivePnl += profit;
        }
      });

      inactivePositions.forEach((order) => {
        let profit = 0;
        const buyValue = order.buyavgPrice * order.buyqty;
        const sellValue = order.sellavgPrice * order.sellqty;

        profit = sellValue - buyValue;

        newInactiveProfits[order._id] = profit;
        totalInactivePnl += profit;
      });

      setProfits(newActiveProfits);
      setTotalPnl(activeTab === "active" ? totalActivePnl : totalInactivePnl);
    };

    calculateProfits();
  }, [activePositions, inactivePositions, prices, activeTab]);

  const handleSquareOff = (positionId, price) => {
    setSquareOffDetails((prevDetails) => ({
      ...prevDetails,
      [positionId]: {
        ...prevDetails[positionId],
        showInput: true,
        price:price
      },
    }));
  };
  const handleCloseOrder = (positionId) => {
    setSquareOffDetails((prevDetails) => ({
      ...prevDetails,
      [positionId]: {
        ...prevDetails[positionId],
        showInput: false,
        price:0
      },
    }));
  };
  // console.log(squareOffDetails);

  const handleInputChange = (positionId, value) => {
    setSquareOffDetails((prevDetails) => ({
      ...prevDetails,
      [positionId]: {
        ...prevDetails[positionId],
        price: value,
      },
    }));
  };

  const handlePlaceOrder = async (positionId) => {
    try {
      const { price } = squareOffDetails[positionId];
      const position = positions.find((pos) => pos._id === positionId);
      const { buyqty, sellqty, cryptoSymbol, orderType } = position;
      let qty = buyqty > 0 ? buyqty : sellqty;
      let type = sellqty > 0 ? "buy" : "sell";
      if (price && positionId) {
        const orderData = { positionId, price };
        const res = sendMessage("squareOffPosition", {
          usertoken: sessionStorage.getItem("user_token"),
          orderDetails: orderData,
          price,
          qty,
          type,
          positionId,
        });
        // if (res) {
        //   toast.success("Position Closed.");
        //   console.log(res);
        //   handleUser(res?.data?.user);
        // }
        setSquareOffDetails({});
        const allPositions = await getAllPosition();
        const active =
          allPositions?.data.filter((pos) => pos.status === "active") || [];
        const inactive =
          allPositions?.data.filter((pos) => pos.status === "inactive") || [];
        setActivePositions(active);
        setInactivePositions(inactive);
        setPositions(activeTab === "active" ? active : inactive);
        console.log(allPositions);
      }
    } catch (error) {
      console.error("Error squaring off position:", error);
    }
  };

  // const handleSquareOff = async (positionId, price) => {
  //   try {
  //     if (price && positionId) {
  //       const res = await squareOffOrder({ positionId, price });
  //       if (res) {
  //         toast.success("Position Close.");
  //         console.log(res);
  //         handleUser(res?.data?.user)
  //       }
  //       const allPositions = await getAllPosition();
  //       const active =
  //         allPositions?.data.filter((pos) => pos.status === "active") || [];
  //       const inactive =
  //         allPositions?.data.filter((pos) => pos.status === "inactive") || [];
  //       setActivePositions(active);
  //       setInactivePositions(inactive);
  //       setPositions(activeTab === "active" ? active : inactive);
  //       console.log(allPositions);
  //     }
  //   } catch (error) {
  //     console.error("Error squaring off position:", error);
  //   }
  // };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "active") {
      setPositions(activePositions);
    } else {
      setPositions(inactivePositions);
    }
  };

  return (
    <div>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "active" ? "active" : ""}`}
          onClick={() => handleTabChange("active")}
        >
          Active Positions
        </button>
        <button
          className={`tab ${activeTab === "inactive" ? "active" : ""}`}
          onClick={() => handleTabChange("inactive")}
        >
          Inactive Positions
        </button>
      </div>
      <br/>
      <div className="total-pnl">
        <strong>Total PnL: </strong> {totalPnl.toFixed(2)}
      </div>
      <br/>

      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Order Type</th>
            <th scope="col">Crypto Symbol</th>
            <th scope="col">Buy Avg Price</th>
            <th scope="col">Buy Qty</th>
            <th scope="col">Sell Avg Price</th>
            <th scope="col">Sell Qty</th>
            {activeTab === "active" && <th scope="col">Live Price</th>}
            <th scope="col">Status</th>
            <th scope="col">Profit/Loss</th>
            {activeTab === "active" && <th scope="col">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {positions &&
            positions.length > 0 &&
            positions.map((order) => (
              <tr key={order._id}>
                <td>{order.orderType}</td>
                <td>{order.cryptoSymbol}</td>
                <td>{order.buyavgPrice}</td>
                <td>{order.buyqty}</td>
                <td>{order.sellavgPrice}</td>
                <td>{order.sellqty}</td>
                {activeTab === "active" && (
                  <td>{prices[order.cryptoSymbol] || "Loading..."}</td>
                )}
                <td>{order.status}</td>
                {activeTab === "active" ? (
                  <td>
                    {profits[order._id]
                      ? profits[order._id].toFixed(2)
                      : "Calculating..."}
                  </td>
                ) : (
                  <td>
                    {Number(
                      order.sellavgPrice * order.sellqty -
                        order.buyavgPrice * order.buyqty
                    ).toFixed(2)}
                  </td>
                )}
                {activeTab === "active" && (
                  <td>
                    {squareOffDetails[order._id]?.showInput ? (
                      <>
                        <input
                          type="number"
                          placeholder="Enter price"
                          value={squareOffDetails[order._id]?.price || ""}
                          onChange={(e) =>
                            handleInputChange(order._id, e.target.value)
                          }
                        />
                        <button onClick={() => handlePlaceOrder(order._id)}>
                          Place
                        </button>
                        <button onClick={()=>handleCloseOrder(order._id)}>X</button>
                      </>
                    ) : (
                      <button
                        onClick={() => handleSquareOff(order._id, prices[order.cryptoSymbol]||0)}
                        disabled={
                          orders &&
                          orders.length > 0 &&
                          orders.some(
                            (o) =>
                              o.positionId === order._id &&
                              o.status === "pending"
                          )
                        }
                      >
                        Square Off
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActivePosition;
