import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/Group 34632.png";

function ARKHAM() {
  useEffect(()=>{
    window.scrollTo({top:0, behavior:"smooth"})
  },[])
  return (
    <>
      <Navbar />
      <div className="ARKHAM-profile">
        <img src={img} />
        <div className="ARKHAM-c">
          <h1>ARKHAM</h1>

          <p>
            Arkham (ARKM) is an intel-to-earn token powering the deanonymization
            of the blockchain with AI.
          </p>
        </div>
        <div className="ARKHAM-d">
          <button>End Time : 2023-7-18</button>
          <button>Subscription</button>
        </div>
      </div>
      <br />
      <div className="airdrop-text-div">
        <div className="airdrop-text">
          <p>
            DTX is a decentralized perpetual exchange built with Blast native
            yield, supporting 100+ crypto markets with up to 100x leverage.
            Unlike order book systems, DTX's unique synthetic architecture
            guarantees a seamless trading experience for traders by eliminating
            slippage and ensuring guaranteed order execution. Additionally, it
            offers flexibility in collateral usage and market-making while
            simultaneously maximizing capital efficiency for LPs. DTX has
            launched a points campaign called “DTX FUEL” and confirmed that it
            will airdrop DTX tokens to users who collect Fuel. Create an account
            at DTX and make trades to earn Fuel. You’ll also get 0.25% FUEL from
            each referral. The Fuel will be converted to DTX tokens in the
            future. You will also get Blast points and 100% of the Blast Gold
            airdrop for trading on DTX.
          </p>
          <br />
          <button>Apply Now</button>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default ARKHAM;
