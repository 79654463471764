import React from "react";
import Navbar from "../Components/Navbar";
import searchicon from "../Assets/Search.svg";
import img1 from "../Assets/add-square-svgrepo-com 1.svg";
import img2 from "../Assets/copy-svgrepo-com 1.svg";
import img3 from "../Assets/Group 34629.png";
import icon from "../Assets/ICon (1).svg";
import icon2 from "../Assets/save-floppy-svgrepo-com 1.png";
import icon3 from "../Assets/Vector (1).png";
import icon4 from "../Assets/monitor-svgrepo-com 1.png";
import icon5 from "../Assets/result-old-svgrepo-com 1.png";
import icon6 from "../Assets/export-3-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import LatestAirdrop from "./Airdrop/LatestAirdrop";


function Airdrop() {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <div className="center stockscreen">
        <h1>Airdrops</h1>
        <br />

        <p style={{ textAlign: "center" }}>
          The Cryptop Token is the key to unlocking the full potential of
          Cryptop. Get even higher earning rates and our lowest borrowing rates.
        </p>
        <br />

        <div className="searchtab">
          <input placeholder="Search" />
          <img src={searchicon} />
        </div>
      </div>
      <br />
      <br />
      <LatestAirdrop />
      <Footer />
    </>
  );
}

export default Airdrop;
