import axios from "axios";
import { headers, jwtHeaders } from "../common/data";
import { toast } from "react-toastify";
import { userBaseUrl as baseUrl } from "../../utils/binanceurl";


export const getWishlistbyuser = async()=>{
    try {
        const res = await axios.get(`${baseUrl}/wishlist/user`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const addWishlist = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/wishlist/create-wishlist`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const removeWishlist = async(wishlistId)=>{
    try {
        const res = await axios.delete(`${baseUrl}/wishlist/${wishlistId}`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const addItemtoWishlist = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/wishlist/add-item`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
        toast.error("Max Limit Reach.")
    }
}

export const removeItemtoWishlist = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/wishlist/remove-item`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getwishlistItem = async(wishlistId)=>{
    try {
        const res = await axios.get(`${baseUrl}/wishlist/${wishlistId}/items`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

