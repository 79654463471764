import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import profile from '../Assets/picture.png'
import msg from '../Assets/material-symbols_chat.png'
import per from '../Assets/picture.png'

function Discussion() {
  return (
    <>
        <Navbar/>
       

    <br/>
    <div className='community-page'>
    <div className='community-page-section'>
    <p className='Discussion-p' style={{textAlign:'center'}}>Discussion</p>
    <h1 className='Discussion-h' style={{textAlign:'center'}}>EURNZD - Seize profitable Opportunity with Anti-Shark Pattern</h1>
<div className='Discussion-jupiter'>
<div className='Discussion-jupiters'>
<div>

  <img src={per}/>
</div>
  <div>
    <h3>By Trading_Jupiter</h3>
    <p>Android Developer</p>
  </div>
  

</div>

<div>
  <p>10 April</p>
</div>
</div>

<div className='teman-teman'>
<p>Halo teman-teman semuanya.
Saya punya pertanyaan, tentang cara untuk menginstall NextJS versi 13, yang dimana NextJS 13 adalah versi terbaru. Saya mempunyai masalah dengan instalasi yang terbaru, saya menda.....</p>
<br/>
<div className='msgcoment'>
  <img src={msg}/>
  <p>3 Comments</p>
</div>

</div>




    <div>

    </div>
</div>
    </div>
    <br/>
        <Footer/>
    </>
  )
}

export default Discussion