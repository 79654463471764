import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";

const EmergencyContactInformation = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [formData, setFormData] = useState({
    emergencyContactName: "",
    relationship: "",
    emergencyContactPhoneNumber: "",
    emergencyContactEmail: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData(userData?.emergencyContactInformation);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      const res = await updateUserData({ emergencyContactInformation: formData });
      if (res) {
        handleUser(res?.data);
      }
    } catch (error) {
      console.error("Error updating emergency contact information:", error);
    }
  };

  return (
    <div className="tab-content-div">
      <div className="tab-content-innerdiv">
        <div className="tab-content-heading">
          <h3>Emergency Contact Information</h3>
          <p>Details about your emergency contacts.</p>
        </div>
        <div className="tab-content-inputs">
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Emergency Contact Name</label>
              <input
                name="emergencyContactName"
                value={formData?.emergencyContactName}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Relationship</label>
              <input
                name="relationship"
                value={formData?.relationship}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Emergency Contact Phone Number</label>
              <input
                name="emergencyContactPhoneNumber"
                value={formData?.emergencyContactPhoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Emergency Contact Email</label>
              <input
                name="emergencyContactEmail"
                value={formData?.emergencyContactEmail}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="savechngs" onClick={handleSubmit}>Save Changes</button>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default EmergencyContactInformation;
