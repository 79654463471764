import React from 'react'
import Navbar from '../Components/Navbar'
import searchicon from '../Assets/Search.svg'
import img1 from '../Assets/add-square-svgrepo-com 1.svg'
import img2 from '../Assets/copy-svgrepo-com 1.svg'
import img3 from '../Assets/Group 34629.png'
import icon from '../Assets/ICon (1).svg'
import icon2 from '../Assets/save-floppy-svgrepo-com 1.png'
import icon3 from '../Assets/Vector (1).png'
import icon4 from '../Assets/monitor-svgrepo-com 1.png'
import icon5 from '../Assets/result-old-svgrepo-com 1.png'
import icon6 from '../Assets/export-3-svgrepo-com 1.png'
import Footer from '../Components/Footer'
import a1 from '../Assets/hashnode-svgrepo-com 1.png'
import a2 from '../Assets/Group 34633.png'
import a3 from '../Assets/Group 34634.png'
import { useNavigate } from 'react-router-dom'



function InitialOffering() {
    const navigate = useNavigate()
  return (
  
        <>
         <Navbar/>
         <br/>
         <br/>
         <div className='center stockscreen'>
           <h1>Initial Offerings</h1>
         <br/>
     
           <p style={{textAlign:'center'}}>The Cryptop Token is the key to unlocking the full potential of Cryptop. Get even higher earning rates and our lowest borrowing rates.</p>
           <br/>
        
        <div className='searchtab'>
         <input placeholder='Search' />
         <img src={searchicon}/>
     
        </div>
         </div>
         <br/>
         <br/>

         <div className='assets-btns'>
            <div className='assets-btns4'>
                <button><img src={a1}/> All Assets</button>
                <button><img src={a1}/>Tradable</button>
                <button><img src={a2}/>Gainers</button>
                <button><img src={a3}/>Losers</button>
                
            </div>
            <div className='assets-btns4'>
            <button>GDP</button>
                <button>1Y</button>
                
            </div>
         </div>
      
 
         <br/>
        <div className='stock-passesall-div'>
        <table class="table">
       <thead class="thead-dark" >
         <tr>
           <th scope="col">Name</th>
           <th scope="col">Symbol</th>
           <th scope="col">Links</th>
           <th scope="col">0% Change</th>
           <th scope="col">Price</th>
           <th scope="col">Volume</th>
     
         </tr>
       </thead>
       <tbody>
         <tr onClick={()=> navigate("/ARKHAM")}>
           <th scope="row">SAH POLYMERS ltd</th>
           <td>SAH</td>
           <td>P&F - F.a</td>
           <td>+3.35%</td>
           <td>$5,653.11000</td>
           <td>458,459</td>
     
         </tr>
         <tr onClick={()=> navigate("/ARKHAM")}>
           <th scope="row">SAH POLYMERS ltd</th>
           <td>SAH</td>
           <td>P&F - F.a</td>
           <td>+3.35%</td>
           <td>$5,653.11000</td>
           <td>458,459</td>
     
         </tr>
         <tr onClick={()=> navigate("/ARKHAM")}>
           <th scope="row">SAH POLYMERS ltd</th>
           <td>SAH</td>
           <td>P&F - F.a</td>
           <td>+3.35%</td>
           <td>$5,653.11000</td>
           <td>458,459</td>
     
         </tr>
       </tbody>
     </table>
        </div>
     
        <Footer/>
        </>
  )
}

export default InitialOffering