import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMain } from "../../hooks/useMain"; // Import your custom hook for API calls
import profile from "../../Assets/Group 34630.png";
import trash from "../../Assets/delete-filled-svgrepo-com 9.png";
import { useNavigate } from "react-router-dom";

const LinkDashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState("");
  const [linkedDashboards, setLinkedDashboards] = useState([]);
  const { addLinkDashboard, getLinkDashboard, deleteDashBoard } = useMain();
  const navigate = useNavigate();

  // Function to fetch linked dashboards
  const getData = async () => {
    try {
      const res = await getLinkDashboard();
      if (res?.data) {
        setLinkedDashboards(res.data);
      } else {
        toast.error("Failed to fetch linked dashboards.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching linked dashboards.");
    }
  };

  // Function to link a new dashboard using a token
  const handleLinkDashboard = async () => {
    if (!token) {
      toast.error("Please enter a valid token.");
      return;
    }

    try {
      const res = await addLinkDashboard({ dashboardtoken: token });
      if (res?.data) {
        toast.success("Dashboard linked successfully!");
        setShowModal(false); // Close the modal
        setToken(""); // Clear the token input
        getData(); // Refresh the dashboard list
      } else {
        toast.error(
          "Failed to link dashboard. Please check the token and try again."
        );
      }
    } catch (error) {
      toast.error("An error occurred while linking the dashboard.");
    }
  };

  // Function to delete a linked dashboard
  const handleDelete = async (id) => {
    try {
      await deleteDashBoard(id);
      getData(); // Refresh the dashboard list after deletion
    } catch (error) {
      toast.error("An error occurred while deleting the dashboard.");
    }
  };

  useEffect(() => {
    getData(); // Fetch linked dashboards on component mount
  }, []);

  return (
    <div>
      <div className="creatDashboard">
        <h1>Link Dashboard</h1>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Link New Dashboard
      </Button>
      </div>

      {/* Modal for entering the token */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Link Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="tokenInput">
              <Form.Label>Enter Token</Form.Label>
              <Form.Control
                type="text"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="Enter your token here"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLinkDashboard}>
            Link Dashboard
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Display linked dashboards */}
      <div className="yourboard">
        {linkedDashboards &&
          linkedDashboards.length > 0 &&
          linkedDashboards.map((ele) => (
            <div className="dashboard-card" key={ele._id}>
              <img src={profile} alt="Dashboard Profile" />
              <div
                className="dashboard-card-text"
                style={{ cursor: "pointer" }}
              >
                <h3 onClick={() => navigate(`/Dashboard/linked/${ele._id}`)}>
                  {ele.name}
                </h3>
                <p>
                  Linked Dashboard{" "}
                  {/* <img
                    src={trash}
                    alt="Delete"
                    onClick={() => handleDelete(ele._id)}
                  /> */}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LinkDashboard;
