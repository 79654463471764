import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import LinkScannerDetail from "./Dashboard/LinkScannerDetail";

function LinkDashBoardDetail() {
  return (
    <>
      <Navbar />
      {/* <br />
      <br />
      <br />
      <br /> */}
      <LinkScannerDetail />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />

      <Footer />
    </>
  );
}

export default LinkDashBoardDetail;
