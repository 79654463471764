import React from "react";

function ConditionQuery({ query, handleQueryChange, index }) {
  return (
    <div className="q-selects">
      <select 
        value={query.interval}
        onChange={(e) => handleQueryChange(index, "interval", e.target.value)}
        style={{
          borderColor:
            query.interval === null ||
            query.interval === undefined ||
            query.interval === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Interval</option>
        <option value="1m">1m</option>
        <option value="3m">3m</option>
        <option value="5m">5m</option>
        <option value="15m">15m</option>
        <option value="30m">30m</option>
        <option value="1h">1h</option>
        <option value="2h">2h</option>
        <option value="4h">4h</option>
        <option value="6h">6h</option>
        <option value="8h">8h</option>
        <option value="12h">12h</option>
        <option value="1d">1d</option>
        <option value="3d">3d</option>
        <option value="1w">1w</option>
        <option value="1M">1M</option>
      </select>

      <select
        value={query.valueType}
        onChange={(e) => handleQueryChange(index, "valueType", e.target.value)}
        style={{
          borderColor:
            query.valueType === null ||
            query.valueType === undefined ||
            query.valueType === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Select Condition</option>
        <option value="open">Open</option>
        <option value="high">High</option>
        <option value="low">Low</option>
        <option value="close">Close</option>
      </select>

      <select
        value={query.operation}
        onChange={(e) => handleQueryChange(index, "operation", e.target.value)}
        style={{
          borderColor:
            query.operation === null ||
            query.operation === undefined ||
            query.operation === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Select Operation</option>
        <option value=">=">{">="}</option>
        <option value="<=">{"<="}</option>
        <option value="==">{"=="}</option>
      </select>

      <select
        value={query.compareinterval}
        onChange={(e) =>
          handleQueryChange(index, "compareinterval", e.target.value)
        }
        style={{
          borderColor:
            query.compareinterval === null ||
            query.compareinterval === undefined ||
            query.compareinterval === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Interval</option>
        <option value="1m">1m</option>
        <option value="3m">3m</option>
        <option value="5m">5m</option>
        <option value="15m">15m</option>
        <option value="30m">30m</option>
        <option value="1h">1h</option>
        <option value="2h">2h</option>
        <option value="4h">4h</option>
        <option value="6h">6h</option>
        <option value="8h">8h</option>
        <option value="12h">12h</option>
        <option value="1d">1d</option>
        <option value="3d">3d</option>
        <option value="1w">1w</option>
        <option value="1M">1M</option>
      </select>

      <select
        value={query.comparevalueType}
        onChange={(e) =>
          handleQueryChange(index, "comparevalueType", e.target.value)
        }
        style={{
          borderColor:
            query.comparevalueType === null ||
            query.comparevalueType === undefined ||
            query.comparevalueType === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Select Condition</option>
        <option value="open">Open</option>
        <option value="high">High</option>
        <option value="low">Low</option>
        <option value="close">Close</option>
      </select>
    </div>
  );
}

export default ConditionQuery;
