import axios  from "axios";
import { headers,  jwtHeaders } from "../common/data";
import { userBaseUrl as baseUrl } from "../../utils/binanceurl";

export const getoptionsymbol_binance = async()=>{
    try {
        const res = await axios.get(`${baseUrl}/binance/option/symbol`)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}