// export const baseUrl = "http://13.234.248.65:8080/api"
export const baseUrl = "https://cs.coinyoga.io/api"
// export const baseUrl = "http://localhost:8080/api"

export const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
}

export const jwtHeaders = () => {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("user_token")}`,
        "Access-Control-Allow-Origin": "*"
    }
    return headers;
}