import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon from '../Assets/Online Money Transfer.svg'
import coin from '../Assets/Bitcoin.svg'
import tenyear from '../Assets/10 years.svg'
import rating from '../Assets/Rating.png'
import dash from '../Assets/Group 1000005963.png'
import icon2 from '../Assets/explorer-dynamic-colorx.png'
import { Link } from 'react-router-dom'
import search from '../Assets/Search1.svg'


function MainHome() {
  return (
    <>
    <Navbar/>
    <div className='cat'>
      <div className='cat-div'>
      <div className='cat-search'>
      <input placeholder='Search'/>
      <img src={search}/>

      </div>
      <div className='cat-heading'>
      <h6>Company</h6>
      <h6>Price</h6>


      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>

      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
   


      </div>
      <div className='cat-div'>
      <div className='cat-search'>
      <input placeholder='Search'/>
      <img src={search}/>

      </div>
      <div className='cat-heading'>
      <h6>Company</h6>
      <h6>Price</h6>


      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>

      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
      <div className='cat-name'>
      <h6>Tata Communication</h6>
      <h6>0.00%</h6>
      <h6>2010.40</h6>



      </div>
   


      </div>
      <div className='cat-graph'>
      <h3>Your Portfolio</h3>
<br/>
        <div class="piechart"></div>
        </div>
    </div>





    {/* <div className='homebanner'>
    <br/>
    <br/>
    <h1>
    Sell  & Buy Crypto <br/>
with ease
<img src={icon}/>
    </h1>
    <p>This is going to be the most exiciting crypto service you have ever tried in your life, keep going </p>
  
   
    </div> */}
    <div className='coincrypto-cards'>
    <div className='coincrypto-cards-div'>
    <div className='coincrypto-card'>
    <img src={coin}/>
    <div className='cc'>
<div className='cc-left'>
<p>BTC/UTC <br/>
<span>
36,272.28<br/>
</span>
31,211.02</p>
</div>
<div className='cc-right'>
<button>2.3 %</button>
</div>


    </div>

    </div>
    <div className='coincrypto-card'>
    <img src={coin}/>
    <div className='cc'>
<div className='cc-left'>
<p>BTC/UTC <br/>
<span>
36,272.28<br/>
</span>
31,211.02</p>
</div>
<div className='cc-right'>
<button>2.3 %</button>
</div>


    </div>

    </div>
    <div className='coincrypto-card'>
    <img src={coin}/>
    <div className='cc'>
<div className='cc-left'>
<p>BTC/UTC <br/>
<span>
36,272.28<br/>
</span>
31,211.02</p>
</div>
<div className='cc-right'>
<button>2.3 %</button>
</div>


    </div>

    </div>
    <div className='coincrypto-card'>
    <img src={coin}/>
    <div className='cc'>
<div className='cc-left'>
<p>BTC/UTC <br/>
<span>
36,272.28<br/>
</span>
31,211.02</p>
</div>
<div className='cc-right'>
<button>2.3 %</button>
</div>


    </div>

    </div>

</div>

    </div>
 
    <br/>
    <br/>
    <div className='somethingaboutus'>
    <br/>
    <br/>
    <h1>Want to Know something about us</h1>
    <br/>
    <br/>
    <br/>

   <div className='something'>
   <div>
        <img src={tenyear}/>
    </div>
<div className='somting2'>
    <p>Years of Coin yoga  <br/><span>Unlock the power of 
Data visualization</span></p>
</div>

<div className='somting3'>
<p>Lorem ipsum Tempor incididunt ut labore et dolore magna aliquat enim veniam quis nostrud exercitation ullamco laboris nis aliquip consequat duis aute irure dolor voluptate.</p>
</div>
   </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>

<div className='rattings'>
<div className='rattings-left'>
<div className='rattings-left-div'>
<div className='rattings-left-text'>
    <h1>$12B+</h1>
    <p>Assets <br/>
management</p>
</div>
    <br/>

<div className='rattings-left-text'>
    <h1>$12B+</h1>
    <p>Assets <br/>
management</p>
</div>

</div>


<br/>
<div className='rattings-left-div'>
<div className='rattings-left-text'>
    <h1>$12B+</h1>
    <p>Assets <br/>
management</p>
</div>
    <br/>

<div className='rattings-left-text'>
    <h1>$12B+</h1>
    <p>Assets <br/>
management</p>
</div>

</div>
</div>
<div className='rattings-right'>
<img src={rating}/>
</div>

</div>
<br/>
<div className='expscreen'>
<div className='expscreen-left'>
    <h1>Explore Screeners</h1>
    <br/>
    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut dolor sit</p>
    <br/>
    
<Link to='/login'>
<button>Get Started Now</button>

</Link>
</div>
<div className='expscreen-right'>
<img src={dash}/>

</div>
</div>


<div className='portfolio'>
<h1>Trusted by 10 Millions+ Users.<br/>
Create your crypto portfolio on Crypto<br/>
 trader  <img src={icon2}/>     in seconds </h1>

</div>
    <Footer/>

   </>
  )
}

export default MainHome