import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMain } from "../../hooks/useMain";
import { FaPen, FaTimes  } from "react-icons/fa";
import LinkScannerCard from "./LinkScannerCard";
import DashboardRate from "./DashboardRate";

const LinkScannerDetail = () => {
  const { id } = useParams();
  const { getSingleDashBoard, updateDashBoard } = useMain();
  const [dashboard, setDashboard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewName(dashboard?.name);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleSubmit = async () => {
    await updateDashBoard({ name: newName }, id);
    setIsEditing(false);
    fetchDashboard();
  };

  const fetchDashboard = async () => {
    if (id) {
      const res = await getSingleDashBoard(id);
      console.log(res);
      setDashboard(res?.data);
    }
  };
  useEffect(() => {
    fetchDashboard();
  }, [id]);

  if (!dashboard) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="center stockscreen">
        {isEditing ? (
          <div>
            <input type="text" value={newName} onChange={handleNameChange} />
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={handleCancelClick} className="btn btn-secondary">
              <FaTimes size={20} />
            </button>
          </div>
        ) : (
          <h1>
            {dashboard.name?.toUpperCase()}
            {/* <button
              onClick={handleEditClick}
              style={{
                marginLeft: "10px",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FaPen size={20} />
            </button> */}
          </h1>
        )}
        <DashboardRate />
        <br />
      </div>

      {/* <TokenGenerator /> */}

      <div className="scanner-grid">
        {dashboard.scanners?.map((scanner) => (
          <LinkScannerCard
            key={scanner._id}
            id={scanner?._id}
            scanner={scanner}
            fetchDashboard={fetchDashboard}
          />
        ))}
      </div>
    </div>
  );
};

export default LinkScannerDetail;
