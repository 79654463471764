import React, { useEffect, useState } from "react";
import img from "../Assets/image 2 (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMain } from "../hooks/useMain";
import { GetCountries } from "react-country-state-city";
import parsePhoneNumberFromString from "libphonenumber-js"
import logo from '../Assets/newcoin.png'
import HelmetBase from "../helmet/HelmetBase";

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [dob, setDob] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [countryid, setCountryid] = useState(0);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    // email: "",
    number: "",
    dob: "",
    countryCode: "",
    country: "",
  });

  const { createuser, verifySignup } = useMain();
  const navigate = useNavigate();

  const handlesetContry = (ind) => {
    const country = countriesList.find((c) => c.phone_code === ind);
 
     setCountry(country?.name);
     setCountryid(country.id);
     setCountryCode(country?.phone_code);
  };

  // const validateEmail = (email) => {
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return regex.test(email);
  // };

  const validatePhoneNumber = (number, countryCode) => {
    let finalNumber = "+"+countryCode+number
    // console.log(finalNumber);
    const phoneNumber = parsePhoneNumberFromString(finalNumber);
    // console.log(phoneNumber);
    return phoneNumber && phoneNumber.isValid();
  };

  const validateDob = (dob) => {
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

    if (!regex.test(dob)) return false;

    const [year, month, day] = dob.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - year - (today.getMonth() < month - 1 || (today.getMonth() === month - 1 && today.getDate() < day) ? 1 : 0);

    return (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day &&
      age >= 18 &&
      age <= 100
    );
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!firstName) newErrors.firstName = "First Name is required.";
    if (!lastName) newErrors.lastName = "Last Name is required.";
    if (!country) newErrors.country = "Country is required.";
    if (!countryCode) newErrors.countryCode = "Country Code is required.";
    // if (!email) newErrors.email = "Email is required.";
    // else if (!validateEmail(email)) newErrors.email = "Invalid email format.";
    if (!number) newErrors.number = "Phone Number is required.";
    else if (!validatePhoneNumber(number, countryCode))
      newErrors.number = "Invalid phone number format.";
    if (!dob) newErrors.dob = "Date of Birth is required.";
    else if (!validateDob(dob))
      newErrors.dob = "Invalid Date of Birth format or age not within 18-100.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});

    // return;

    const payload = {
      firstName,
      lastName,
      email,
      mobile: number,
      dob,
      countryCode,
      country
    };
    setLoader(true);
    try {
      const res = await createuser(payload);
      if (res.data) {
        setLoader(false);
        setIsOtpSent(true);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleVerifyOtp = async () => {
    const newErrors = {};
    if (otp.length!=6) newErrors.otp = "OTP must be 6 Digit.";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
    try {
      const payload = {
        mobile: number,
        otp,
      };
      setLoader(true);

      const res = await verifySignup(payload);
      if (res.data) {
        setLoader(false);
        setIsOtpSent(true);
        toast.success("Sign up successful!");
        navigate("/login");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <HelmetBase
      title={"Start Your Crypto Journey Today - Sign Up for Crypto24x7"}
      description={
        "Join the exciting world of cryptocurrency with Crypto24x7. Sign up for a free account and start exploring the potential of crypto."
      }
    />
      <div className="login">
        <div className="login-left">
        <Link to='/'>

<img className="sing-logo" src={logo}/>
</Link>
          <div className="login-left-center">
            <img src={img} alt="Sign Up Illustration" />
          </div>
        </div>
        <div className="login-right">
          <Link to="/login">
            <button className="signup-btn">Login</button>
          </Link>
          <div className="login-form">
            <h2>Sign Up</h2>
            <br />
            {!isOtpSent && (
              <form onSubmit={handleSignUp}>
                <input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <br />
                {errors.firstName && (
                  <p className="error">{errors.firstName}</p>
                )}
                <input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <br />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
                {/* <input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                <select
                    value={countryCode}
                    onChange={(e) => handlesetContry(e.target.value)}
                  >
                    <option value="">Select Country Code</option>
                    {countriesList.map((country) => (
                      <option key={country.isoCode} value={country.phone_code} id={country.id}>
                        {country.name} (+{country.phone_code})
                      </option>
                    ))}
                  </select>
                <br />
                {errors.country && <p className="error">{errors.country}</p>}
                {/* <input
                  placeholder="Country Code"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value.replace(/\D/g, ''))}
                />
                <br />
                {errors.countryCode && <p className="error">{errors.countryCode}</p>} */}
                <input
                  placeholder="Phone Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value.replace(/\D/g, ''))}
                />
                <br />
                {errors.number && <p className="error">{errors.number}</p>}
                <input
                  type="date"
                  placeholder="Date of Birth (YYYY-MM-DD)"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
                <br />
                {errors.dob && <p className="error">{errors.dob}</p>}
                <div className="center">
                  <button type="submit" className="logbtn">
                    Submit
                  </button>
                </div>
              </form>
            )}
            {isOtpSent && (
              <>
                <input
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value.replace(/\D/g, ''))}
                />
                <br />
                {errors.otp && <p className="error">{errors.otp}</p>}
                <div className="center">
                  <button className="logbtn" onClick={handleVerifyOtp}>
                    Verify OTP
                  </button>
                </div>
              </>
            )}
            <br />
            <p>
              Already have an account?{" "}
              <Link to="/login">
                <span>Log In</span>
              </Link>{" "}
              now
            </p>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default SignUp;
