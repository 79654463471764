// Ap
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Login';
import LoginCode from './Pages/LoginCode';
import MFACode from './Pages/MFACode';
import SignUp from './Pages/SignUp';
import InitialOffering from './Pages/InitialOffering';
import Dashboard from './Pages/Dashboard';
import Profile from './Pages/Profile';
import PremimumandSubscription from './Pages/PremimumandSubscription';
import Funds from './Pages/Funds';
import Airdrop from './Pages/Airdrop';
import AirDropDetail from './Pages/AirDropDetail';
import Stacking from './Pages/Stacking';
import ARKHAM from './Pages/ARKHAM';
import Stacking2 from './Pages/Stacking2';
import CryptoDashboard from './Pages/CryptoDashboard';
import Community from './Pages/Community';
import Discussion from './Pages/Discussion';
import Mainpage from './Pages/Mainpage';
import Studys from './Pages/Studys';
import Trading from './Pages/Trading';
import Spot from './Pages/Spot';
import MainHome from './Pages/MainHome';
import TermsAndCondition from './Pages/TermsAndCondition';
import Policy from './Pages/Policy';
import TreadingDetail from './Pages/TreadingDetail';
import { WebSocketProvider } from './utils/useWebSocket';
import { OptionWebSocketProvider } from './utils/optionWebSocket';
import DashboardDetail from './Pages/DashboardDetail';
import EditScanner from './Pages/EditScanner';
import UpcomingFeatures from './Pages/UpcomingFeatures';
import DashboardGlobal from './Pages/DashboardGlobal';
import PrivateDashboardDetail from './Pages/PrivateDashboardDetail';
import LinkDashBoardDetail from './Pages/LinkDashBoardDetail';
import Partner from './Pages/Partner';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/upcoming" element={<UpcomingFeatures />} />
        <Route path="/Screener" element={<Home />} />
        <Route path="/Screener/:id" element={<EditScanner />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logincode" element={<LoginCode />} />
        <Route path="/mfa" element={<MFACode />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/initial" element={<InitialOffering />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Dashboard/private/:id" element={<PrivateDashboardDetail />} />
        <Route path="/Dashboard/linked/:id" element={<LinkDashBoardDetail />} />
        <Route path="/Dashboard/:id" element={<DashboardDetail />} />
        <Route path="/Dashboard/global/:id" element={<DashboardGlobal />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/PremimumandSubscription" element={<PremimumandSubscription />} />
        <Route path="/Funds" element={<Funds />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/Airdropdetail/:id" element={<AirDropDetail />} />
        <Route path="/Stack" element={<Stacking />} />
        <Route path="/Stack2" element={<Stacking2 />} />
        <Route 
          path="/spot/:crypto?" 
          element={
            <WebSocketProvider>
              <Trading />
            </WebSocketProvider>
          } 
        />
        <Route path="/option" element={
          <OptionWebSocketProvider>
            <Spot />
          </OptionWebSocketProvider>
        } />
        <Route path="/ARKHAM" element={<ARKHAM />} />
        <Route path="/crypto" element={<CryptoDashboard />} />
        <Route path="/community" element={<Community />} />
        <Route path="/discussion" element={<Discussion />} />
        <Route path="/main" element={<MainHome />} />
        <Route path="/t&c" element={<TermsAndCondition />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/study" element={<Studys />} />
        <Route path="/tradingdetail" element={<TreadingDetail />} />
        <Route path="/partner" element={<Partner/>} />
      </Routes>
    </Router>
  );
}

export default App;
