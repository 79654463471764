import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";

const ChartModal = ({ show, onHide, symbol }) => {
  const container = useRef();

  useEffect(() => {
    if (!show) return; // Don't run the effect if modal is not shown

    const containerDiv = container.current;
    while (containerDiv.firstChild) {
      containerDiv.removeChild(containerDiv.firstChild);
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: `BINANCE:${symbol}`,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "en",
      hide_side_toolbar: false,
      allow_symbol_change: true,
      calendar: false,
    });

    setTimeout(() => {
      containerDiv.appendChild(script);
    }, 100);

    return () => {
      while (containerDiv.firstChild) {
        containerDiv.removeChild(containerDiv.firstChild);
      }
    };
  }, [show, symbol]);

  

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          TradingView Chart for {symbol}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "70vh" }}>
        <div
          className="tradingview-widget-container"
          ref={container}
          style={{ height: "100%", width: "100%" }}
        >
          <div
            className="tradingview-widget-container__widget"
            style={{ height: "calc(100% - 32px)", width: "100%" }}
          ></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChartModal;
