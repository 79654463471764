import axios from "axios";
import { jwtHeaders } from "../common/data";
import { userBaseUrl as baseUrl } from "../../utils/binanceurl";

export const getUser = async () => {
    try {
        const res = await axios.get(`${baseUrl}/user/single`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const logout = async () => {
    try {
        const res = await axios.get(`${baseUrl}/user/logout`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const updateUserData = async (data) => {
    try {
        const res = await axios.put(`${baseUrl}/user/update`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const updateProfilePic = async (data) => {
    try {
        const res = await axios.put(`${baseUrl}/user/profilepic`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const updateCoverPic = async (data) => {
    try {
        const res = await axios.put(`${baseUrl}/user/coverpic`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const googleLogin = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/user/google/login`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const updateEmailOtpsend = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/user/email/send`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error);
        throw new Error(error?.response?.data?.message);
    }
}

export const updateEmailverify = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/user/email/verify`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res?.data
        }
    } catch (error) {
        console.log(error);
        throw new Error(error?.response?.data?.message);
    }
}

export const updatePhoneOtpsend = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/user/phone/send`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error);
        throw new Error(error?.response?.data?.message);
    }
}

export const updatePhoneverify = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/user/phone/verify`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res?.data
        }
    } catch (error) {
        console.log(error);
        throw new Error(error?.response?.data?.message);
    }
}