// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wishlists_nav_menu {
    display: flex;
    flex-direction: row;
   gap: 20px;
  }
  
  .wishlist-item {
    /* background-color: #f0f0f0; */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 5px;
    margin: 0px 0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wishlist-item:hover {
    background-color: #e0e0e0;
  }
  
  .wishlist-item.active {
    background-color: #8771FC;
    color: white;
    transform: scale(1.05);
  }
  
  .wishlist-item span {
    display: inline-block;
    font-size: 12px;

  }
  
  .wishlist-item button {
    display: inline-block;
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #ff4d4d;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .wishlist-item button:hover {
    background-color: #ff1a1a;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Trading/Wishlist.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;GACpB,SAAS;EACV;;EAEA;IACE,+BAA+B;IAC/B,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,iDAAiD;IACjD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,eAAe;;EAEjB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".wishlists_nav_menu {\n    display: flex;\n    flex-direction: row;\n   gap: 20px;\n  }\n  \n  .wishlist-item {\n    /* background-color: #f0f0f0; */\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 5px 5px;\n    margin: 0px 0;\n    cursor: pointer;\n    transition: background-color 0.3s, transform 0.3s;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .wishlist-item:hover {\n    background-color: #e0e0e0;\n  }\n  \n  .wishlist-item.active {\n    background-color: #8771FC;\n    color: white;\n    transform: scale(1.05);\n  }\n  \n  .wishlist-item span {\n    display: inline-block;\n    font-size: 12px;\n\n  }\n  \n  .wishlist-item button {\n    display: inline-block;\n    margin-left: 10px;\n    padding: 5px 10px;\n    border: none;\n    background-color: #ff4d4d;\n    color: white;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .wishlist-item button:hover {\n    background-color: #ff1a1a;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
