import React from "react";
import Wishlist from "./Wishlist";
import TradingGraph from "./TradingGraph";

const TradingPanel = () => {
  return (
    <div className="cat">
      <Wishlist />
     <div style={{height:"600px", width:"100%"}}>
     <TradingGraph />
     </div>
    </div>
  );
};

export default TradingPanel;
