import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Termsimg from '../Assets/Frame_14.png'
import HelmetBase from "../helmet/HelmetBase"
import { Link } from 'react-router-dom'

function Policy() {
  return (
    <>
    <HelmetBase
      title={"Your Data is Safe: Read Crypto24x7's Privacy Policy"}
      description={
        "Committed to user privacy, Crypto24x7 outlines its data practices in our Privacy Policy. Gain transparency and trust by understanding how we handle your information."      }
    />
    <Navbar/>
    <div className='TermsAndCondition'>
    <div className='TermsAndCondition-left'>
    <h1>Legal and Privacy</h1>
    <p>View our agreements and other legal resources</p>
    <Link to='/signup'>

    <button>Sign Up Now</button>
    </Link>

</div>
<div className='TermsAndCondition-right'>
<img src={Termsimg}/>
</div>

    </div>
    <br/>
    <br/>
    <div className='terms'>

    

    <p>Coinyoga, Pte Ltd ("us", "we", or "our") operates the Coinyoga website (the "Service").</p>
    <br/>
    <p>This page informs you of our policies regarding the collection, use, and disclosure of Personal Information when you use our Service.</p>
    <br/>
<p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
<br/>
<p>By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Service.</p>


<br/>
<h1>Information We Collect</h1>
<br/><p>We collect information from you when you register on our site or fill out a form. Any data we request that is not required will be specified as voluntary or optional. However, you may visit our site anonymously.</p>
<br/>
<ul>
    <li>Registration Information: Information provided when you fill in the registration form, including but not limited to your name, email, and password.</li>
    <li>Backup and Restore Data: Information collected when you use the Backup and Restore data feature, which may include your watchlists, portfolios, saved screeners, alerts, and configurations.</li>

</ul>
<br/>
<h1>Use of Information</h1>
<br/><p>Any of the information we collect from you may be used in one of the following ways:</p>
<br/>
<ul>
    <li>To Personalize Your Experience: Your information helps us better respond to your individual needs.</li>
    <li>To Improve Our Website: We continually strive to improve our service based on the information and feedback we receive from you.</li>
    <li>To Improve Customer Service: Your information helps us more effectively respond to your customer service and support needs.</li>

</ul>
<br/>
<h1>Log Data</h1>
<br/><p>We may use third-party services such as Google Analytics that collect, monitor, and analyze app performance and user interaction to increase our service's quality. These third-party service providers have their own privacy policies addressing how they use such information.</p>
<br/>

<h1>User Data Deletion</h1>
<br/><p>You have the right to request the deletion of your Coinyoga account and any related data from our system when you wish to terminate the use of Coinyoga products. The request for data deletion can be made via email.</p>
<br/>
<p>Upon receipt of the request, Coinyoga will delete all relevant user data (including existing copies) in our system as soon as reasonably practicable and within a maximum period of 30 days. Remember, deleted data cannot be recovered, and the deletion will terminate all access to certain specific functionalities of the product.</p>
<br/>
<h1>Cookies</h1>
<br/>
<p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.</p>
<br/>
<h1>Disclosure of Information</h1>
<br/>
<p>We do not sell, trade, or otherwise transfer your personal information except in accordance with this policy. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect our or others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
<br/>
<h1>Changes to This Privacy Policy</h1>
<br/>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
<br/>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<br/>

<h1>Contact Us</h1>
<a href="mailto:anoopgecbh@gmail.com" className="mail-link">
        Mail To: anoopgecbh@gmail.com
      </a>
<br/>
<br />





    </div>





    <Footer/>
</>
  )
}

export default Policy