import React, { useState } from "react";
import profile from "../../Assets/Group 34631.png";
import icon1 from "../../Assets/time-filled-svgrepo-com 1.png";
import icon2 from "../../Assets/calender-svgrepo-com 2.png";
import pencilIcon from "../../Assets/pencil-icon.png"; // Add pencil icon asset
import { useMain } from "../../hooks/useMain";

const ProfileHeader = () => {
  const { userData, updateProfilePic, handleUser } = useMain(); 
  const [profileImage, setProfileImage] = useState(userData?.profilePicture || profile);

  const handleProfilePicChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("pic", file);

      try {
        const res = await updateProfilePic(formData);
        if (res) {
          const updatedUserData = res.data;
          handleUser(updatedUserData);
          setProfileImage(URL.createObjectURL(file));
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
      }
    }
  };

  return (
    <div className="profile">
      <div className="profile-inner">
        <div className="profile-pic-container">
          <img className="profile-inner-img" src={userData?.profilePicture||profile} alt="Profile" style={{borderRadius:"50%", width:"300px", height:"300px"}} />
          <input
            type="file"
            id="profilePicInput"
            style={{ display: "none" }}
            onChange={handleProfilePicChange}
          />
          <img
            src={pencilIcon}
            alt="Edit"
            className="edit-icon"
            onClick={() => document.getElementById('profilePicInput').click()}
          />
        </div>
        <div>
          <h1>
            {userData?.firstName} {userData?.lastName}
          </h1>
          <p>{userData?.email}</p>
          <div className="profile-inner-p">
            <p>
              <img src={icon1} alt="Last Visit" />
              Last Visit Mar 14
            </p>
            {/* <p>
              <img src={icon2} alt="Last Visit" />
              Last Visit Mar 14
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
