import React from 'react'

const RSIQuery = ({ query, handleQueryChange, index }) => {
  return (
    <div className="q-selects">
       <select
        value={query.interval}
        onChange={(e) => handleQueryChange(index, "interval", e.target.value)}
        style={{
          borderColor:
            query.interval === null ||
            query.interval === undefined ||
            query.interval === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Interval</option>
        <option value="1m">1m</option>
        <option value="3m">3m</option>
        <option value="5m">5m</option>
        <option value="15m">15m</option>
        <option value="30m">30m</option>
        <option value="1h">1h</option>
        <option value="2h">2h</option>
        <option value="4h">4h</option>
        <option value="6h">6h</option>
        <option value="8h">8h</option>
        <option value="12h">12h</option>
        <option value="1d">1d</option>
        <option value="3d">3d</option>
        <option value="1w">1w</option>
        <option value="1M">1M</option>
      </select>
      <input
        type="number"
        placeholder="Enter Period"
        value={query.period}
        onChange={(e) => handleQueryChange(index, "period", e.target.value)}
        style={{
          borderColor:
            query.period === null ||
            query.period === undefined ||
            query.period === ""
              ? "red"
              : "initial",
        }}
      />
      <select
        value={query.operation}
        onChange={(e) => handleQueryChange(index, "operation", e.target.value)}
        style={{
          borderColor:
            query.operation === null ||
            query.operation === undefined ||
            query.operation === ""
              ? "red"
              : "initial",
        }}
      >
        <option value="">Select Operation</option>
        <option value=">=">{">="}</option>
        <option value="<=">{"<="}</option>
        <option value="==">{"=="}</option>
      </select>
      <input
        type="number"
        placeholder="Enter Value"
        value={query.value}
        onChange={(e) => handleQueryChange(index, "value", e.target.value)}
        style={{
          borderColor:
            query.value === null ||
            query.value === undefined ||
            query.value === ""
              ? "red"
              : "initial",
        }}
      />
    </div>
  )
}

export default RSIQuery
