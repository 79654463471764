import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useMain } from '../../hooks/useMain';
import { useParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';

const DashboardRate = () => {
  const { id } = useParams(); // Dashboard ID from URL
  const { getratingbyUserDashboard, rateDashboard } = useMain(); // Custom hooks
  const [hasRated, setHasRated] = useState(false); // Whether the user has already rated
  const [rating, setRating] = useState(0); // User's current rating selection
  const [hover, setHover] = useState(0); // Hover state for star rating
  const [message, setMessage] = useState(''); // Feedback message

  useEffect(() => {
    // Check if user has already rated the dashboard
    const checkUserRating = async () => {
      try {
        const response = await getratingbyUserDashboard(id);
        if (response?.data?.hasRated) {
          setHasRated(true);
        }
      } catch (error) {
        console.error('Error checking rating:', error);
      }
    };

    checkUserRating();
  }, [id, getratingbyUserDashboard]);

  const handleRatingSubmit = async (selectedRating) => {
    try {
      await rateDashboard({rating:selectedRating, dashboardId:id});
      setMessage('Rating submitted successfully!');
      setHasRated(true);
    } catch (error) {
      setMessage('Error submitting rating.');
      console.error('Error rating dashboard:', error);
    }
  };

  return (
    <div className="mt-4">
      {message && <Alert variant="info">{message}</Alert>}

      {hasRated ? (
        <Alert variant="success">You have already rated this dashboard.</Alert>
      ) : (
        <div>
          <h5>Rate this Dashboard</h5>
          <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <FaStar
                key={star}
                size={30}
                className="star"
                color={star <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                onClick={() => {
                  setRating(star);
                  handleRatingSubmit(star);
                }}
                onMouseEnter={() => setHover(star)}
                onMouseLeave={() => setHover(0)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardRate;
