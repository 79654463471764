import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useMain } from "../../hooks/useMain";
import { useParams } from "react-router-dom";

const TokenGenerator = () => {
  const { id } = useParams();
  const [token, setToken] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateTokenApi } = useMain();

  const handleGenerateToken = async () => {
    setIsGenerating(true);
    try {
      const response = await generateTokenApi(id); // Replace with actual API
      console.log(response);
      setToken(response.data); // Update with actual response structure
    } catch (error) {
      console.error("Error generating token:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token);
    alert("Token copied to clipboard!");
  };

  return (
    <div className="my-3">
      <Button onClick={handleGenerateToken} disabled={isGenerating}>
        {isGenerating ? "Generating..." : "Generate Token"}
      </Button>

      {token && (
        <InputGroup className="mt-3">
          <Form.Control type="text" value={token} readOnly />
          <Button variant="outline-secondary" onClick={handleCopyToken}>
            Copy
          </Button>
        </InputGroup>
      )}
    </div>
  );
};

export default TokenGenerator;
