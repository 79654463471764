import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";

const Preferences = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [formData, setFormData] = useState({
    preferredLanguage: "",
    timeZone: "",
    privacySettings: {
      publicProfile: false,
      privateProfile: false,
      customSettings: {},
    },
  });

  useEffect(() => {
    if (userData && userData.preferences) {
      setFormData({ ...formData, ...userData.preferences});
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        privacySettings: {
          ...prevFormData.privacySettings,
          [name]: checked,
          // Uncheck other option if one is checked
          ...(name === "publicProfile" && checked ? { privateProfile: false } : {}),
          ...(name === "privateProfile" && checked ? { publicProfile: false } : {}),
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUserData({ preferences: formData });
      if (res) {
        handleUser(res.data);
      }
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  return (
    <div className="tab-content-div">
      <div className="tab-content-innerdiv">
        <div className="tab-content-heading">
          <h3>Preferences</h3>
          <p>Customize your preferences.</p>
        </div>
        <div className="tab-content-inputs">
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Preferred Language</label>
              <input
                type="text"
                name="preferredLanguage"
                value={formData?.preferredLanguage}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Time Zone</label>
              <input
                type="text"
                name="timeZone"
                value={formData?.timeZone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Public Profile</label>
              <input
                type="checkbox"
                name="publicProfile"
                checked={formData?.privacySettings?.publicProfile}
                onChange={handleChange}
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Private Profile</label>
              <input
                type="checkbox"
                name="privateProfile"
                checked={formData?.privacySettings?.privateProfile}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="savechngs" onClick={handleSubmit}>Save Changes</button>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default Preferences;
