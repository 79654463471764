import React, { useEffect, useState } from "react";
import { useMain } from "../../hooks/useMain";
import { toast } from "react-toastify";
import { useOptionWebSocketContext } from "../../utils/optionWebSocket";

const OptionBuySell = () => {
  const {
    placeOptionOrder,
    orderCall,
    setOrderCall,
    userData,
    handleUser,
    setIsoptionCall,
    optionData,
    optionCall,
  } = useMain();
  const [price, setPrice] = useState(optionData?.price);
  const [amount, setAmount] = useState(0);
  const [qty, setqty] = useState(1);
  const { sendMessage } = useOptionWebSocketContext();
  console.log(optionData);

  useEffect(() => {
    setPrice(optionData?.price);
    setAmount(Number(optionData?.price || 0) * qty);
  }, [optionData]);
  const handleplaceOptionOrder = async (orderType) => {
    if (price <= 0) {
      return toast.error("Price not less than 0");
    }
    if (qty <= 0) {
      return toast.error("Quantity not less than 0");
    }
    const { cryptoSymbol } = optionData;
    if (cryptoSymbol && cryptoSymbol.toLowerCase().startsWith("eth")) {
      const orderData = {
        ...optionData,
        amount: price * qty,
        price: price,
        status: "pending",
        qty: qty,
        orderType: orderType,
        baseAsset: "ETH",
      };
      const res = sendMessage("newOrder", {
        usertoken: sessionStorage.getItem("user_token"),
        orderDetails: orderData,
      });
    } else if (cryptoSymbol && cryptoSymbol.toLowerCase().startsWith("btc")) {
      const orderData = {
        ...optionData,
        amount: price * qty,
        price: price,
        status: "pending",
        qty: qty,
        orderType: orderType,
        baseAsset: "BTC",
      };
      const res = sendMessage("newOrder", {
        usertoken: sessionStorage.getItem("user_token"),
        orderDetails: orderData,
      });
    } else {
      toast.error("You can Place Only Eth And Btc Option.");
    }
    setIsoptionCall(false)
  };
  // console.log(userData);

  return (
    <div className="buy-sell-div">
      <div className="buy-sell">
        {/* Top right side "*" button */}
        <button
         style={{
          // position: "absolute",
          // top: "20px",
          // right: "10px",
          fontSize: "20px",
          border: "none",
          background: "1px solid red",
          cursor: "pointer",
          margin:"10px",
          backgroundColor:'#ff4d4d',
          padding:'10px 25px',
          borderRadius:'10px',
          color:'white'
        }}
          onClick={() => setIsoptionCall(false)}
        >
          Close
        </button>
        {/* <br />
        <br /> */}

        <div className="but-sell-heading">
          <button
            style={{
              backgroundColor: "#8771FC",
              color: "white",
              padding: "10px 30px",
              borderRadius: "12px",
            }}
          >
            Limit
          </button>
          {/* <button>Market</button>
          <button>Stop-Limit</button> */}
        </div>
        {/* <br />
        <br /> */}
        <div className="buy-sell-section">
          <div className="buy-sell-left">
            <h4>
              {optionData?.cryptoSymbol} {"   "}
              <span>{optionData?.optionType?.toUpperCase()}</span>
            </h4>
            <div className="coinprice">
              <input
                placeholder="Coin Name"
                value={qty}
                type="number"
                onChange={(e) => setqty(e.target.value)}
              />
              <h5>{qty} Qty</h5>
            </div>
            <br />
            <div className="coinprice">
              <input
                placeholder="Price"
                value={price}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
              />
              <h5>{price} USDT Price</h5>
            </div>
            <br />
            <div className="coinprice">
              <input
                placeholder="Amount"
                value={price * qty}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                disabled
              />
              <h5>{amount} USDT Amount</h5>
            </div>
            <br />

            <label>Max Buy</label>
            <br />
            <label>Est. Fee.</label>
            <br />
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                className="buy-sell-left-buttom"
                onClick={() => handleplaceOptionOrder("buy")}
              >
                Buy {optionData?.optionType?.toUpperCase()}
              </button>
              <button
                className="buy-sell-right-buttom"
                onClick={() => handleplaceOptionOrder("sell")}
              >
                Sell {optionData?.optionType?.toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionBuySell;
