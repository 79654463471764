import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScannerDetail from "./Dashboard/ScannerDetail";
import PrivateScannerDetail from "./Dashboard/PrivateScannerDetail";

function PrivateDashboardDetail() {
  return (
    <>
      <Navbar />
      {/* <br />
      <br />
      <br />
      <br /> */}
      <PrivateScannerDetail />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />

      <Footer />
    </>
  );
}

export default PrivateDashboardDetail;
