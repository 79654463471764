import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dash from "../../../Assets/Add a subheading2.png";
import dash2 from "../../../Assets/Group 1000005965.svg";
import dash3 from "../../../Assets/Group 1000005966.svg";
import dash4 from "../../../Assets/Group 1000005967.svg";
import icon2 from "../../../Assets/explorer-dynamic-colorx.png";
import { Link } from "react-router-dom";

const SliderPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="expscreen">
            <div className="expscreen-left">
              <h1>Explore Screeners</h1>
              <br />
              <p style={{ textAlign: "justify" }}>
                Discover top-performing assets with powerful screeners designed
                to enhance your trading strategy. Whether you're tracking market
                movers or identifying hidden gems, our advanced tools help you
                stay ahead of the curve.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash} alt="Dashboard" />
            </div>
          </div>
        </div>
        <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-right">
              <img src={dash2} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h1>Explore Funds</h1>
              <br />
              <p style={{ textAlign: "justify" }}>
                Unlock a world of investment opportunities with our expertly
                curated funds. Whether you're seeking growth, stability, or
                diversification, our selection offers something for every
                investor.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="expscreen">
            <div className="expscreen-left">
              <h1>Explore Trading</h1>
              <br />
              <p style={{ textAlign: "justify" }}>
                Step into the world of trading with tools and strategies
                designed for success. Whether you're a seasoned trader or just
                starting out, our platform provides the insights and resources
                you need to navigate the markets with confidence.
              </p>
              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
            <div className="expscreen-right">
              <img src={dash3} alt="Dashboard" />
            </div>
          </div>
        </div>
        <div>
          <div className="expscreen expscreen2">
            <div className="expscreen-right">
              <img src={dash4} alt="Dashboard" />
            </div>
            <div className="expscreen-left">
              <h1>Explore Initial Offerings</h1>
              <br />
              <p style={{ textAlign: "justify" }}>
                Discover groundbreaking opportunities with our exclusive access
                to initial offerings. Be among the first to invest in innovative
                projects and emerging markets, positioning yourself at the
                forefront of the next big trend.
              </p>

              <br />
              <Link to="/login">
                <button>Get Started Now</button>
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderPage;
