import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Termsimg from '../Assets/Frame_14.png'
import HelmetBase from "../helmet/HelmetBase";
import { Link } from 'react-router-dom';

function TermsAndCondition() {
  return (
    <>
      <HelmetBase
      title={"Crypto24x7 Terms of Service: Understanding Your Agreement"}
      description={
        "Crypto24x7's Terms of Service govern the use of our platform. Take a moment to read them and ensure a seamless and compliant trading experience."
      }
    />
        <Navbar/>
        <div className='TermsAndCondition'>
        <div className='TermsAndCondition-left'>
        <h1>Terms And Condition</h1>
        <p>View our agreements and other legal resources</p>
        <Link to='/signup'>

        <button>Sign Up Now</button>
        </Link>

</div>
  <div className='TermsAndCondition-right'>
<img src={Termsimg}/>
</div>

        </div>
        <br/>
        <br/>
        <div className='terms'>

        <h1>AGREEMENT / TERMS OF USE</h1>
        <br/>

        <p>By using the information and services ("Services") on coinyoga.io, users of the Services ("You") agree to all of the following terms.</p>
        <br/>
        <p>We refer to these Terms of Use as the “Agreement.” We may update or amend these Terms of Use from time to time by posting such updates or amendments on the Site. Your use of the Site after we post any such updates or amendments will constitute your agreement to those updates or amendments.</p>
        <br/>
<p >This agreement requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or class actions, and also limits the remedies available to you in the event of a dispute.</p>


<br/><p>The Terms and Conditions of use set forth herein apply to the website titled Coinyoga.io, which is owned, operated, licensed, and controlled by Coinyoga. This website is for the purpose of providing technical and fundamental analysis and other supporting services. As a user and potential customer, we invite you to browse our website. Users are not permitted to reproduce, distribute, modify, display, or make use of the contents of this website, except to download material in temporary storage on one computer/mobile solely for personal and non-commercial use. Any scans, charts, dashboards, or other content created on the site by the user are licensed under the GNU license.</p>
<br/><p>Any unauthorized use of the services and content may violate copyright laws, trademark laws, as well as the laws of privacy. Coinyoga is not responsible for the same. ‘Unauthorized use’ shall mean and include any usage of the digital content provided on this website not in conformity with the rights granted with respect to the same. Except as expressly provided above, nothing contained herein shall be construed as conferring any license or right under any copyright or other intellectual property right. Do not use this website if your country does not allow these activities. If any legal action arises, you must be answerable.</p>


<br/>
<h1>Subscription</h1>


<p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
<br/><p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Coinyoga cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Coinyoga customer support team.</p>
<br/><p>A valid payment method, including credit card or PayPal, is required to process the payment for your Subscription. You shall provide Coinyoga with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method. By submitting such payment information, you automatically authorize Coinyoga to charge all Subscription fees incurred through your account to any such payment instruments.</p>
<br/>
<p>Should automatic billing fail to occur for any reason, Coinyoga will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
<br/>

<h1>
Fee Changes
</h1>

<br/>
<p>Coinyoga, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle. Coinyoga will provide you with reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
<br/>
<p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
<br/>
<h1>
Refund Rules/Policies
</h1>
<br/>
<p>Since Coinyoga.io is offering non-tangible irrevocable goods, we do not issue refunds once the order is accomplished and the service is activated. As a customer, you are responsible for understanding this upon purchasing any services at our site. We shall take utmost care in regards to the functionality and uptime of our services, but we do not guarantee this. In certain cases, we may increase your subscription duration in the event of an outage or situations beyond our control.</p>
<br/>
<h1>
Limitation of Liability
</h1>
<br/>
<p>You acknowledge and agree that we are only willing to provide access to the site and to provide the services if you agree to certain limitations of our liability to you and to third parties. Neither CoinYoga nor its directors, officers, employees, contractors, agents, or sponsors are responsible or liable to you or anyone else for any loss or injury or any indirect, incidental, consequential, special, exemplary, punitive, or other damages under any contract, negligence, strict liability, or other theory arising out of or relating in any way to: (i) the use of, delays in operation, transmission, or response of, or inability to use the site or the services; (ii) any content contained on the site and/or the services; (iii) statements or conduct posted or made publicly available on the site and/or the services; (iv) any product or service purchased or obtained through the site; (v) any action taken in response to or as a result of any information available on the site or the services; (vi) any damage caused by mistakes, inaccuracies, omissions, errors, interruptions, or loss of access to, deletion of, failure to store, failure to back up, or alteration of any content on the site or the services; or (vii) any other failure of performance of the site or services or other matter relating to the site and/or the services, whether or not caused by events beyond the control of our directors, officers, employees, contractors, agents, or sponsors, including, but not limited to, acts of nature, communications line failure, theft, destruction, or unauthorized access to the site or services or content stored therein.

In no event shall CoinYoga’s total liability to you for any and all damages, losses, and causes of action (whether in contract, tort, statutory, or otherwise) exceed one rupee (Rs 1.00). Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of certain types of liability. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit liabilities, the scope and duration of such warranty and the extent of our liability will be the minimum permitted under such applicable law.</p>
<br/>
<h1>
Force Majeure
</h1>
<br/>
<p>Coinyoga shall not be responsible for delay or default in the performance of their obligations due to contingencies beyond their control, such as (including but not limited to) losses caused directly or indirectly by exchange or market rulings, suspension of trading, fire, flood, civil commotion, earthquake, war, strikes, failure of systems, failure of hardware, SMS delays, failure of internet links, or government/regulatory action.</p>
<br/>
<h1>
Governing Law and Jurisdiction
</h1>
<br/>
<p>Transactions between you and Coinyoga shall be governed by and construed in accordance with the laws of UAE, without regard to any conflicts of laws of other nations. Any litigation regarding this agreement or any transaction between customer and Coinyoga.io, or any action at law or in equity arising out of or relating to this agreement or transaction, shall be filed only in the Competent Courts of Ras Al Khaimah, United Arab Emirates, and the customer hereby agrees, consents, and submits to the jurisdiction of such courts for the purpose of litigating any such action.</p>
<br/>
<br/>



        </div>





        <Footer/>
    </>
  )
}

export default TermsAndCondition