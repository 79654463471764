import React from "react";
import logo from "../Assets/newcoin.png";
import { Link } from "react-router-dom";
import ChatBotComponent from "./ChatBotComponent";

function Footer() {
  return (
//     <>
//       <div className="footer">
//         <ul>
//           <img className="foot-logo" src={logo} />
//         </ul>

//         <ul>
//           Links
          
//           <Link to="/" className="nonek">
//             <li>Home</li>
//           </Link>
//           <Link to="/Screener" className="nonek">
//             <li>Screeners </li>
//           </Link>
//           <Link to="/Screener" className="nonek">
//             <li>Trading</li>
//           </Link>
//           {/* <Link  to='/Screener' className='nonek'>
        
//         <li>Contact us</li>
//         </Link> */}
//         </ul>
//         <ul>
//           Links
          
//           <Link to="/Funds" className="nonek">
//             <li>Funds</li>
//           </Link>
//           <Link to="/community" className="nonek">
//             <li>Community </li>
//           </Link>
//           <Link to="/community" className="nonek">
//             <li>More</li>
//           </Link>
//         </ul>
//         <ul>
//           Legal
          
//           <Link to="/policy" className="nonek">
//             <li>Privacy Policy</li>
//           </Link>
//           <Link to="/Terms" className="nonek">
//             <li>Terms and Conditions </li>
//           </Link>
//         </ul>
//         <ul>
//           Our Information
          
//           <Link className="nonek">
//             <li>Contact Us</li>
//           </Link>
//         </ul>
//       </div>
//      <div className="center">
//      <div className="line2">

// </div>
//      </div>
//       <div className="footer-bottom">
//         <p>© Crypto 24x7All rights Reserved. Designed & Developed by <Link style={{color:'black' , fontWeight:'700' , textDecoration:'none'}} to='https://strixdigital.in/'>Strix Digital</Link></p>
//       </div>
//     </>
<>

<footer className="new_footer_area bg_color">
      <div className="new_footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="f_widget company_widget wow fadeInLeft"
                data-wow-delay="0.2s"
                style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInLeft" }}
              >
              <Link to='/'>

              <img className="foot-logo" src={logo}/>
              </Link>
                {/* <h3 className="f-title f_600 t_color f_size_18">Get in Touch</h3>
                <p>Don’t miss any updates of our new templates and extensions.!</p>
              */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6" style={{zIndex:'100'}}>
              <div
                className="f_widget about-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.4s"
                style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft" }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Links</h3>
                <ul className="list-unstyled f_list">
                <Link to="/" className="nonek">
             <li>Home</li>
           </Link>
         
           <Link to="/Screener" className="nonek">
             <li>Screener </li>
          </Link>
           <Link to="/option" className="nonek">
            <li>Option</li>
          </Link>
          <Link  to='/spot' className='nonek'>
        
          <li>Spot</li>
          </Link> 
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="f_widget about-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.6s"
                style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInLeft" }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Links</h3>
                <ul className="list-unstyled f_list">
                <Link to="/Funds" className="nonek">
             <li>Funds</li>
           </Link>
           <Link to="/community" className="nonek">
             <li>Community </li>
           </Link>
           {/* <Link to="/partner" className="nonek">
             <li>Partner </li>
           </Link> */}
           {/* <Link to="/community" className="nonek">
             <li>More</li>
           </Link> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="f_widget about-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.6s"
                style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInLeft" }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Legal</h3>
                <ul className="list-unstyled f_list">
                <Link to="/policy" className="nonek">
             <li>Privacy Policy</li>
           </Link>
           <Link to="/t&c" className="nonek">
             <li>T&C</li>
           </Link>
           <Link to="https://in.tradingview.com/" className="nonek" target="_blank" rel="noopener noreferrer">
            <li>Charts powered by Tradingview</li>
          </Link>

          
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bg">
          <div className="footer_bg_one"></div>
          <div className="footer_bg_two"></div>
          <div className="footer_bg_3"></div>

        </div>
        
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">

              <p className="mb-0 f_400">© Coyasoft FZ-LLC. All rights Reserved.</p>
            </div>
            {/* <div className="col-lg-6 col-sm-6 text-right">
              <p>
              Designed & Developed by <Link style={{color:'black' , fontWeight:'700' , textDecoration:'none'}} to='https://strixdigital.in/'>Strix Digital</Link>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
    <ChatBotComponent/>
</>
  );
}

export default Footer;
