import React, { useState } from "react";
import { useMain } from "../../hooks/useMain";
import { toast } from "react-toastify";
import { useWebSocketContext } from "../../utils/useWebSocket";
import { binanceUrl } from "../../utils/binanceurl";

const BuySell = () => {
  const { setcurName, setIsorderCall, curName, placeOrder, curPrice, setcurPrice, orderCall, setOrderCall, userData, handleUser } = useMain();
  const [price, setPrice] = useState(curPrice);
  const [amount, setAmount] = useState(0);
  const [qty, setqty] = useState(1);
  const { sendMessage } = useWebSocketContext();

  const handlePlaceOrder = async (orderType) => {
    if(price <=0){
        return toast.error("Price not less than 0")
    }
    if(qty <= 0){
        return toast.error("Quantity not less than 0")
    }
    const orderData = {
      orderType: orderType,
      cryptoSymbol: curName,
      amount: price*qty,
      price: price,
      status: "pending",
      qty: qty,
    };
    const res = sendMessage('newOrder', { usertoken: sessionStorage.getItem("user_token"), orderDetails: orderData });
    setIsorderCall(false)

    // const res = await placeOrder(orderData);
    console.log(res);
    // if (res) {
    //   setcurName("");
    //   setcurPrice(0)
    //   toast.success("Order Placed");
    //   setIsorderCall("")
    //   setOrderCall(!orderCall)
    //   handleUser(res?.data)
    // }
  };
  // console.log(userData);

  return (
    <div className="buy-sell-div">
      <div className="buy-sell">
        {/* Top right side "*" button */}
        <button
          style={{
            // position: "absolute",
            // top: "20px",
            // right: "10px",
            fontSize: "20px",
            border: "none",
            background: "1px solid red",
            cursor: "pointer",
            margin:"10px",
            backgroundColor:'#ff4d4d',
            padding:'10px 25px',
            borderRadius:'10px',
            color:'white'
          }}
          onClick={() => setIsorderCall(false)}
        >
          Close
        </button>
        <br />
       

        <div className="but-sell-heading">
          <button style={{
            backgroundColor:'#8771FC',
            color:'white',
            padding:'10px 30px',
            borderRadius:'12px'
          }}>Limit</button>
          {/* <button>Market</button>
          <button>Stop-Limit</button> */}
        </div>
        
        <div className="buy-sell-section">
          <div className="buy-sell-left">
            <h4>{curName}</h4>
            <div className="coinprice">
              <input
                placeholder="Coin Name"
                value={qty}
                type="number"
                onChange={(e) => setqty(e.target.value)}
              />
              <h5>{qty} Qty</h5>
            </div>
            <br />
            <div className="coinprice">
              <input
                placeholder="Price"
                value={price}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
              />
              <h5>{price} USDT Price</h5>
            </div>
            <br />
            <div className="coinprice">
              <input
                placeholder="Amount"
                value={price * qty}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                disabled
              />
              <h5>{amount} USDT Amount</h5>
            </div>
            <br />

            <label>Max Buy</label>
            <br />
            <label>Est. Fee.</label>
            <br />
            <div style={{display:"flex", gap:"10px"}}>
            <button
              className="buy-sell-left-buttom"
              onClick={() => handlePlaceOrder("buy")}
            >
              Buy BTC
            </button>
            <button
              className="buy-sell-right-buttom"
              onClick={() => handlePlaceOrder("sell")}
            >
              Sell BTC
            </button>
            </div>
          </div>
          {/* <div className="buy-sell-right">
            <div className="coinprice">
              <input placeholder="Price" />
              <h5>63381.20 USDT</h5>
            </div>
            <br />
            <div className="coinprice">
              <input placeholder="Price" />
              <h5>63381.20 USDT</h5>
            </div>
            <br />
            <div className="coinprice">
              <input placeholder="Price" />
              <h5>63381.20 USDT</h5>
            </div>
            <br />

            <label>Max Sell</label>
            <br />
            <label>Est. Fee.</label>
            <br />
            <button
              className="buy-sell-right-buttom"
              onClick={() => handlePlaceOrder("sell")}
            >
              Sell BTC
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BuySell;
