import React, { useEffect, useState } from "react";
import { useMain } from "../../hooks/useMain";
import ProfessionalInformation from "./ProfessionalInformation";
import EducationalInformation from "./EducationalInformation";
import SocialMediaLinks from "./SocialMediaLinks";
import Preferences from "./Preferences";
import EmergencyContactInformation from "./EmergencyContactInformation";
import { MdEdit } from "react-icons/md";
import EmailVerificationModal from "../Modal/EmailVerificationModal";
import MobileVerificationModal from "../Modal/MobileVerificationModal";
import { GetCountries, GetState, GetStates } from "react-country-state-city";

const BasicInfo = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityid, setcityid] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    gender: "",
    dob: "",
    mobile: "",
    contactInformation: {
      address: {
        streetAddress: "",
        city: "",
        stateProvinceRegion: "",
        postalZipCode: "",
        country: "",
      },
      secondaryEmail: "",
      secondaryPhoneNumber: "",
    },
  });

  const [errors, setErrors] = useState({
    dob: "",
    secondEmail: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        ...formData,
        ...userData,
        dob: userData?.dob ? userData?.dob?.split("T")[0] : "",
      });
      const countryIndex = countriesList.findIndex(
        (country) =>
          country?.name === userData?.contactInformation?.address?.country
      );
      if (countryIndex !== -1) {
        setCountryid(countryIndex);
        GetState(countriesList[countryIndex]?.id).then((result) => {
          setStateList(result);
          const stateIndex = result.findIndex(
            (state) =>
              state?.name ===
              userData?.contactInformation?.address?.stateProvinceRegion
          );
          if (stateIndex !== -1) {
            setstateid(stateIndex);
          }
        });
      }
    }
  }, [userData, countriesList]);
  // console.log(formData);

  const validateDateOfBirth = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age >= 18 && age <= 100;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length === 1) {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData((prevState) => {
        let temp = { ...prevState };
        let obj = temp;
        for (let i = 0; i < keys.length - 1; i++) {
          obj = obj[keys[i]];
        }
        obj[keys[keys.length - 1]] = value;
        return temp;
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    const newErrors = {};
    if (formData?.dob && !validateDateOfBirth(formData.dob)) {
      newErrors.dob = "Date of birth must be between 18 and 100 years.";
    }
    if (
      formData?.contactInformation?.secondaryEmail &&
      !validateEmail(formData?.contactInformation?.secondaryEmail)
    ) {
      newErrors.secondEmail = "Invalid email format.";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({ dob: "", secondEmail: "" });
    try {
      const res = await updateUserData(formData);
      if (res) {
        console.log(res);
        handleUser(res?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const handlesetContry = (ind) => {
    const country = countriesList[ind];
    setCountryid(ind);
    GetState(country?.id).then((result) => {
      setStateList(result);
    });
    setFormData((prevState) => ({
      ...prevState,
      contactInformation: {
        ...prevState.contactInformation,
        address: {
          ...prevState.contactInformation.address,
          country: country?.name,
          stateProvinceRegion: "",
        },
      },
    }));
  };

  const handlesetState = async (ind) => {
    const state = stateList[ind];
    setstateid(ind);
    setFormData((prevState) => ({
      ...prevState,
      contactInformation: {
        ...prevState.contactInformation,
        address: {
          ...prevState.contactInformation.address,
          stateProvinceRegion: state?.name,
        },
      },
    }));
  };

  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  const maxDate = new Date(
    today.getFullYear() - 12,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  console.log(minDate, maxDate);

  return (
    <>
      <p>
        <div className="tab-content-div">
          <div className="tab-content-innerdiv">
            <div className="tab-content-heading">
              <h3>Public Info</h3>
              <p>
                This information will be publicly displayed and visible for all
                users.
              </p>
            </div>
            <div className="tab-content-inputs">
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>First Name</label>
                  <input
                    name="firstName"
                    value={formData?.firstName}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="tab-content-inputs-col">
                  <label>Last Name</label>
                  <input
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              <br />
              <p>
                Links to your social media are visible under every idea or
                script you publish, depending on your subscription type.
              </p>
              <br />
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>Username</label>
                  <input
                    name="username"
                    value={formData?.username}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="tab-content-inputs-col">
                  <div className="d-flex justify-content-between gap-3">
                    <label>Email</label>
                    <MdEdit
                      style={{ marginRight: 5 }}
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="tab-content-inputs-col">
                  <label>Gender</label>
                  <select
                    name="gender"
                    value={formData?.gender}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <br />
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>Date of Birth</label>
                  <input
                    style={{ color: "black" }}
                    name="dob"
                    type="date"
                    min={minDate}
                    max={maxDate}
                    value={formData?.dob}
                    onChange={handleChange}
                  />
                  {errors.dob && <p className="error">{errors.dob}</p>}
                </div>
                <div className="tab-content-inputs-col">
                  <div className="d-flex justify-content-between gap-3">
                    <label>Phone Number</label>
                    <MdEdit
                      style={{ marginRight: 5 }}
                      onClick={() => setShowModal1(true)}
                    />
                  </div>
                  <input
                    name="mobile"
                    value={formData?.mobile}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                {/* <div className="tab-content-inputs-col">
                  <label>User name</label>
                  <input placeholder="User name" />
                </div> */}
              </div>
              <br />
              {/* <div className="tab-check">
                <p>Social Networks in my Signature</p>
                <input type="checkbox" />
              </div>
              <br /> */}
              <button className="savechngs" onClick={handleSubmit}>
                Save Changes
              </button>
              <br />
              <br />
              <br />
            </div>
          </div>
          <br />
          <div className="tab-content-innerdiv">
            <div className="tab-content-heading">
              <h3>Contact Information</h3>
              <p>This information will not be publicly displayed.</p>
            </div>
            <div className="tab-content-inputs">
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>Country</label>
                  {/* <input
                    name="contactInformation.address.country"
                    value={formData?.contactInformation?.address?.country}
                    onChange={handleChange}
                  /> */}
                  <select
                    className="input-box"
                    onChange={(e) => handlesetContry(e.target.value)}
                    value={countryid}
                    defaultValue={
                      formData?.contactInformation?.address?.country
                    }
                  >
                    {countriesList &&
                      countriesList.map((item, index) => (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="tab-content-inputs-col">
                  <label>State/Province/Region</label>
                  {/* <input
                    name="contactInformation.address.stateProvinceRegion"
                    value={
                      formData?.contactInformation?.address?.stateProvinceRegion
                    }
                    onChange={handleChange}
                  /> */}
                  <select
                    className="input-box"
                    onChange={(e) => handlesetState(e.target.value)}
                    value={stateid}
                  >
                    {stateList &&
                      stateList.map((item, index) => (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <br />
              <p>
                Links to your social media are visible under every idea or
                script you publish, depending on your subscription type.
              </p>
              <br />
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>City</label>
                  <input
                    name="contactInformation.address.city"
                    value={formData?.contactInformation?.address?.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="tab-content-inputs-col">
                  <label>Postal/ZIP Code</label>
                  <input
                    type="text"
                    maxLength="6"
                    pattern="\d{0,6}"
                    name="contactInformation.address.postalZipCode"
                    value={formData?.contactInformation?.address?.postalZipCode}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setFormData((prevState) => ({
                        ...prevState,
                        contactInformation: {
                          ...prevState.contactInformation,
                          address: {
                            ...prevState.contactInformation.address,
                            postalZipCode: value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
                <div className="tab-content-inputs-col">
                  <label>Street Address</label>
                  <input
                    name="contactInformation.address.streetAddress"
                    value={formData?.contactInformation?.address?.streetAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <br />
              <div className="tab-content-inputs-row">
                <div className="tab-content-inputs-col">
                  <label>Secondary Email</label>
                  <input
                    name="contactInformation.secondaryEmail"
                    value={formData?.contactInformation?.secondaryEmail}
                    onChange={handleChange}
                  />
                  {errors.secondEmail && (
                    <p className="error">{errors.secondEmail}</p>
                  )}
                </div>
                <div className="tab-content-inputs-col">
                  <label>Secondary Phone Number</label>
                  <input
                    type="text"
                    maxLength="10"
                    pattern="\d{0,9}"
                    name="contactInformation.secondaryPhoneNumber"
                    value={formData?.contactInformation?.secondaryPhoneNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setFormData((prevState) => ({
                        ...prevState,
                        contactInformation: {
                          ...prevState.contactInformation,
                          secondaryPhoneNumber: value,
                        },
                      }));
                    }}
                  />
                </div>
                {/* <div className="tab-content-inputs-col">
                  <label>User name</label>
                  <input placeholder="User name" />
                </div> */}
              </div>
              <br />
              {/* <div className="tab-check">
                <p>Social Networks in my Signature</p>
                <input type="checkbox" />
              </div>
              <br /> */}
              <button className="savechngs" onClick={handleSubmit}>
                Save Changes
              </button>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </p>
      {/* <ProfessionalInformation /> */}
      {/* <EducationalInformation /> */}
      {/* <SocialMediaLinks /> */}
      {/* <ProfessionalInformation /> */}
      {/* <Preferences /> */}
      {/* <EmergencyContactInformation /> */}

      {/* ---------------Model ---------------------- */}
      <EmailVerificationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        // email={formData.email}
      />
      <MobileVerificationModal
        show={showModal1}
        handleClose={() => setShowModal1(false)}
        // email={formData.email}
      />
    </>
  );
};

export default BasicInfo;
