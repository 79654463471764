import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import icon from "../Assets/growth-svgrepo-com 29.png";
import search from "../Assets/Search1.svg";
import arrow from "../Assets/Group 1000005963.svg";
import graph from "../Assets/graph.png";
import TradingPanel from "./Trading/TradingPanel";
import PaperOrder from "./PaperOrder";
import BuySell from "./Trading/BuySell";
import { useMain } from "../hooks/useMain";
import Ticker from "./Trading/Ticker";
import HelmetBase from "../helmet/HelmetBase";

function Trading() {
  const { isorderCall, userData, orderCall } = useMain();
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  useEffect(()=>{
    setToggle(false)
  },[orderCall])
  // console.log(orderCall);
  return (
    <>
      <HelmetBase
      title={"Effortless Crypto Trading - Buy & Sell Crypto on Crypto24x7"}
      description={
        "crypto24x7 empowers you to trade your favorite cryptos seamlessly. Enjoy a secure and reliable platform with a variety of coins to choose from. Get started today and claim your ₹100 cashback bonus!"
      }
    />
      <Navbar />
      {/* <br />
      <br /> */}
      {/* <div className="center stockscreen">
        <h1>Trading</h1>
        <br />

        <p style={{ textAlign: "center" }}>
          The Cryptop Token is the key to unlocking the full potential of
          Cryptop. Get even higher
          <br /> earning rates and our lowest borrowing rates.
        </p>
        <br />
      </div> */}
      {/* <br />
      <br /> */}
      <div style={{ display:"flex" ,justifyContent:"end"}}>
        <div className="stockrates-div" >
          <p className="stockrates-divp">Wallet</p>
          <p>{userData?.wallet?.currency}{" "}{Number(userData?.wallet?.balance||0).toFixed(2)}</p>
        </div>
      </div>
      <Ticker />
      <br />
      <TradingPanel />
      <br />
      <br />
      {isorderCall && <BuySell />}
      <br />
      <br />
      <div className="order-accodian">
        <div className="order-accodian-div">
          <div className="order-accodian-div-2">
            <p>Order Mangement</p>
            <img src={arrow} onClick={handleToggle} />
          </div>
          <div>{toggle && <PaperOrder isCalling={toggle} />}</div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Trading;
