import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/image 20.png";
import fire from "../../Assets/image 21.png";
import setting from "../../Assets/tools-svgrepo-com 1.png";
import { useMain } from "../../hooks/useMain";

const LatestAirdrop = () => {
  const { getAirDropData } = useMain();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await getAirDropData();
    if (res?.data) {
      setData(res?.data?.data);
    }
  };

  console.log(data);

  return (
    <div>
      <div className="creatDashboard">
        <h1>Latest Airdrops</h1>
      </div>
      <div className="airdrop-cards ">
        <div className="airdrop-cards-div">
          <div className="air-card-section-2">
            {data &&
              data?.length > 0 &&
              data?.map((airdrop, ind) => (
                <Link className="air-card nonelist" style={{textDecoration:'none'}} to={`/Airdropdetail/${airdrop.id}`}>
                  <img src={logo} className="air-card-logo" style={{ important:true}} />
                  <div className="air-card-text">
                    <h4>
                    {airdrop.coin.name} <img src={fire} alt="fire" />
                    </h4>
                    <p>
                      <img src={setting} />
                      Actions: {airdrop.description?.substring(0, 20)}
                    </p>
                  </div>
                </Link>
              ))}
            {/* <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link>
            <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link>
            <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link> */}
            {/* </div>
          <br />
          <div className="air-card-section">
            <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link>
            <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link>
            <Link className="air-card nonelist" to="/Airdropdetail">
              <img src={logo} />
              <div className="air-card-text">
                <h4>
                  Pac Finance <img src={fire} />
                </h4>
                <p>
                  <img src={setting} />
                  Actions: Collect Points
                </p>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestAirdrop;
