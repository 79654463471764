import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMain } from "../../hooks/useMain";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import ScannerCardGlobal from "./ScannerCardGlobal";

const ScannerGlobal = () => {
  const { id } = useParams();
  const { getSingleDashBoard, copyDashboard } = useMain();
  const [dashboard, setDashboard] = useState(null);

  const fetchDashboard = async () => {
    if (id) {
      const res = await getSingleDashBoard(id);
      console.log(res);
      setDashboard(res?.data);
    }
  };
  useEffect(() => {
    fetchDashboard();
  }, [id]);

  const handleCopyDashboard = async()=>{
    const res = await copyDashboard({dashboardId:id})
    if(res.data){
        toast.success("DashBoard Added.")
    }
  }


  if (!dashboard) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="center stockscreen">
        <h1>{dashboard.name?.toUpperCase()}</h1>
        <br />
        <Button onClick={handleCopyDashboard}>Copy DashBoard</Button>
      </div>

      <div className="scanner-grid">
        {dashboard.scanners?.map((scanner) => (
          <ScannerCardGlobal key={scanner._id} id={scanner?._id} scanner={scanner} fetchDashboard={fetchDashboard} />
        ))}
      </div>
    </div>
  );
};

export default ScannerGlobal;
