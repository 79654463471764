import React, { useState, useEffect } from "react";
import { useMain } from "../hooks/useMain";
import ActivePosition from "./Trading/ActivePosition";
import { useWebSocketContext } from "../utils/useWebSocket";
import { binanceUrl } from "../utils/binanceurl";
import PendingOrder from "./Trading/order/PendingOrder";

const PaperOrder = ({ isCalling }) => {
  const { getAllOrder, orderCall, spotOrder } = useMain();
  const [activeTab, setActiveTab] = useState(1);
  const [orders, setOrders] = useState([]);
  // const [prices, setPrices] = useState({});
  // const [profits, setProfits] = useState({});
  const { connected, orders: websocketOrders, sendMessage } = useWebSocketContext();
  // console.log(spotOrder);
  useEffect(() => {
    if (connected) {
      setOrders(spotOrder);
    }
  }, [isCalling, connected, websocketOrders, spotOrder]);

  // useEffect(() => {
  //   const fetchOrders = async () => {
  //     if (isCalling) {
  //       const fetchedOrders = await getAllOrder();
  //       setOrders(fetchedOrders?.data);
  //     }
  //   };
  //   // fetchOrders();
  // }, [isCalling, orderCall, activeTab]);


  return (
    <div className="stock-passesall-div">
      <div className="Run-scan-div" style={{ justifyContent: "start" }}>
        <div className="Run-scan">
          <button
            className={`Run-scan-btn ${activeTab === 1 ? "Export" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            Active Position
          </button>
          <button
            className={`Run-scan-btn ${activeTab === 2 ? "Export" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            Pending Order
          </button>
          <button
            className={`Run-scan-btn ${activeTab === 3 ? "Export" : ""}`}
            onClick={() => setActiveTab(3)}
          >
            Day's History
          </button>
        </div>
      </div>
      {activeTab === 1 && <ActivePosition isCalling={isCalling} />}
      {activeTab === 2 && ( <PendingOrder  orders={orders} sendMessage={sendMessage} />
        // <table className="table">
        //   <thead className="thead-dark">
        //     <tr>
        //       <th scope="col">User ID</th>
        //       <th scope="col">Order Type</th>
        //       <th scope="col">Crypto Symbol</th>
        //       <th scope="col">Amount</th>
        //       <th scope="col">Order Price</th>
        //       {/* <th scope="col">Live Price</th> */}
        //       <th scope="col">Status</th>
        //       <th scope="col">Quantity</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {orders && orders.length > 0 && orders
        //       .filter((order) => order.status === "pending")
        //       .map((order) => (
        //         <tr key={order._id}>
        //           <td>{order.userId}</td>
        //           <td>{order.orderType}</td>
        //           <td>{order.cryptoSymbol}</td>
        //           <td>{order.amount}</td>
        //           <td>{order.price}</td>
        //           {/* <td>{prices[order.cryptoSymbol] || 'Loading...'}</td> */}
        //           <td>{order.status}</td>
        //           <td>{order.qty}</td>
        //         </tr>
        //       ))}
        //   </tbody>
        // </table>
      )}
      {activeTab === 3 && (
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">User ID</th>
              <th scope="col">Order Type</th>
              <th scope="col">Crypto Symbol</th>
              <th scope="col">Amount</th>
              <th scope="col">Order Price</th>
              {/* <th scope="col">Live Price</th> */}
              <th scope="col">Status</th>
              <th scope="col">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {orders && orders.length > 0 && orders
              .filter((order) => order.status === "completed")
              .map((order) => (
                <tr key={order._id}>
                  <td>{order.userId}</td>
                  <td>{order.orderType}</td>
                  <td>{order.cryptoSymbol}</td>
                  <td>{order.amount}</td>
                  <td>{order.price}</td>
                  {/* <td>{prices[order.cryptoSymbol] || 'Loading...'}</td> */}
                  <td>{order.status}</td>
                  <td>{order.qty}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {/* <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">User ID</th>
            <th scope="col">Order Type</th>
            <th scope="col">Crypto Symbol</th>
            <th scope="col">Amount</th>
            <th scope="col">Order Price</th>
            <th scope="col">Live Price</th>
            <th scope="col">Status</th>
            <th scope="col">Quantity</th>
            {activeTab === 1 && <th scope="col">Profit/Loss</th>}
            {activeTab === 1 && <th scope="col">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {orders && orders.length>0 && orders
            .filter((order) => {
              if (activeTab === 1) return order.status === "completed";
              if (activeTab === 2) return order.status === "pending";
              if (activeTab === 3) return order.status === "completed";
              return false;
            })
            .map((order) => (
              <tr key={order._id}>
                <td>{order.userId}</td>
                <td>{order.orderType}</td>
                <td>{order.cryptoSymbol}</td>
                <td>{order.amount}</td>
                <td>{order.price}</td>
                <td>{prices[order.cryptoSymbol] || 'Loading...'}</td>
                <td>{order.status}</td>
                <td>{order.qty}</td>
                {activeTab === 1 && (
                  <>
                    <td>{profits[order._id] ? profits[order._id].toFixed(2) : 'Calculating...'}</td>
                    <td>
                      <button onClick={() => handleSquareOff(order._id)}>Square Off</button>
                    </td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default PaperOrder;
