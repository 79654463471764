import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";

const ProfessionalInformation = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [formData, setFormData] = useState({
    jobTitle: "",
    companyName: "",
    industry: "",
    workExperience: "",
    languagesSpoken: "",
  });

  useEffect(() => {
    if (userData && userData.professionalInformation) {
      setFormData(userData.professionalInformation);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUserData({ professionalInformation: formData });
      if (res) {
        handleUser(res.data);
      }
    } catch (error) {
      console.error("Error updating professional information:", error);
    }
  };

  return (
    <div className="tab-content-div">
      <div className="tab-content-innerdiv">
        <div className="tab-content-heading">
          <h3>Professional Information</h3>
          <p>Details about your professional background.</p>
        </div>
        <div className="tab-content-inputs">
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Job Title</label>
              <input 
                type="text"
                name="jobTitle"
                value={formData?.jobTitle} 
                onChange={handleChange} 
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Company Name</label>
              <input 
                type="text"
                name="companyName"
                value={formData?.companyName} 
                onChange={handleChange} 
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Industry</label>
              <input 
                type="text"
                name="industry"
                value={formData?.industry} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Work Experience</label>
              <input 
                type="text"
                name="workExperience"
                value={formData?.workExperience} 
                onChange={handleChange} 
              />
            </div>
            <div className="tab-content-inputs-col">
              <label>Languages Spoken</label>
              <input 
                type="text"
                name="languagesSpoken"
                value={formData?.languagesSpoken} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <button className="savechngs" onClick={handleSubmit}>Save Changes</button>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalInformation;
