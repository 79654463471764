import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import searchicon from '../Assets/Search.svg'
import img from '../Assets/image 85.png'
import { Link } from 'react-router-dom'
function Stacking() {
  return (
   <>
    <Navbar/>
    <br/>
    <br/>
    <div className='center stockscreen'>
      <h1>Stacking</h1>
    <br/>

      <p style={{textAlign:'center'}}>The Cryptop Token is the key to unlocking the full potential of Cryptop. Get even higher earning rates and our lowest borrowing rates.</p>
      <br/>
   
   <div className='searchtab'>
    <input placeholder='Search' />
    <img src={searchicon}/>

   </div>
   <br/>

<div className='stackbtn'>
    <button className='Launchpad'>Launchpad</button>
    <button>Launchpad</button>

</div>
<br/>
 
    </div>
    <br/>
    <div className='creatDashboard'>
    <h1>Latest Airdrops</h1>


</div>
<br/>
<div className='airdrop-cards'>
<div className='airdrop-cards-div'>
<div className='air-card-section'>
<Link to='/ARKHAM' style={{textDecoration:'none'}} className='stack-card nonelist'>
<img src={img}/>
<br/>
<br/>
<div className='stack-card-btn'>
    <h2>Arkham</h2>
    <button>Subscription</button>
</div>
<p>Arkham (ARKM) is an <br/>intel-to-earn token powering the<br/> deanonymization of the<br/> blockchain with AI.</p>
<hr/>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>


<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>
</Link>
<Link to='/ARKHAM' style={{textDecoration:'none'}} className='stack-card nonelist'>
<img src={img}/>
<br/>
<br/>
<div className='stack-card-btn'>
    <h2>Arkham</h2>
    <button>Subscription</button>
</div>
<p>Arkham (ARKM) is an <br/>intel-to-earn token powering the<br/> deanonymization of the<br/> blockchain with AI.</p>
<hr/>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>


<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>
</Link>
<Link to='/ARKHAM' style={{textDecoration:'none'}} className='stack-card nonelist'>
<img src={img}/>
<br/>
<br/>
<div className='stack-card-btn'>
    <h2>Arkham</h2>
    <button>Subscription</button>
</div>
<p>Arkham (ARKM) is an <br/>intel-to-earn token powering the<br/> deanonymization of the<br/> blockchain with AI.</p>
<hr/>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>

<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>


<div className='stack-card-offer'>
    <h4>Tokens Offered</h4>
    <h5>50,000,000.0000 ARKM</h5>
</div>
</Link>
</div>
<br/>


</div>

</div>
    <br/>
    <Footer/>
   </>
  )
}

export default Stacking