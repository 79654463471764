import axios from "axios";
import {  jwtHeaders } from "../common/data";
import { spotBaseUrl as baseUrl } from "../../utils/binanceurl";

export const placeOrder = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/paper/orders`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const squareOffOrder = async(data)=>{
    try {
        const res = await axios.post(`${baseUrl}/paper/sqaureoff`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllPosition = async (query) => {
    try {
        let url = `${baseUrl}/paper/position`;
        if (query) {
            url += `?positionType=${query}`;
        }
        const res = await axios.get(url, { headers: jwtHeaders() });
        if (res?.status === 200) {
            return res.data;
        } else {
            console.error(`Error: Received status code ${res?.status}`);
        }
    } catch (error) {
        console.error(error.message);
    }
};

export const updateOrder = async(id, data)=>{
    try {
        const res = await axios.put(`${baseUrl}/paper/orders/${id}`, data, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllOrder = async()=>{
    try {
        const res = await axios.get(`${baseUrl}/paper/orders`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getSingleOrder = async(id)=>{
    try {
        const res = await axios.get(`${baseUrl}/paper/orders/${id}`, {headers:jwtHeaders()})
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}