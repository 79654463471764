import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useMain } from "../../hooks/useMain";
import parsePhoneNumberFromString from "libphonenumber-js";

const MobileVerificationModal = ({ show, handleClose }) => {
  const { updatePhoneOtpsend, updatePhoneverify, handleUser, userData } =
    useMain();
  // console.log(userData);
  const [otp, setOtp] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errors, setErrors] = useState({
    countryCode: "",
    mobile: "",
    otp: "",
  });

  const validatePhoneNumber = (number, countryCode) => {
    let finalNumber = "+" + countryCode + number;
    console.log(finalNumber);
    const phoneNumber = parsePhoneNumberFromString(finalNumber);
    console.log(phoneNumber);
    return phoneNumber && phoneNumber.isValid();
  };

  const validateCountryCode = (input) => {
    const countryCodeRegex = /\d{1,4}$/;
    return countryCodeRegex.test(input);
  };

  const handleSendOtp = async () => {
    const newErrors = {};
    if (!validateCountryCode(countryCode)) {
      newErrors.countryCode = "Invalid country code";
    }
    if (!validatePhoneNumber(mobile, countryCode)) {
      newErrors.mobile = "Invalid mobile number";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (mobile === userData?.mobile) {
      return toast.error("Phone number already exists");
    }

    setErrors({ countryCode: "", mobile: "" });

    try {
      const res = await updatePhoneOtpsend({ countryCode, mobile });
      if (res) {
        toast.success("OTP sent to your phone.");
        setIsOtpSent(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVerifyOtp = async () => {
    const newErrors = {};
    if (!otp) {
      newErrors.otp = "OTP is required.";
    } else if (otp.length !== 6) {
      newErrors.otp = "OTP must be 6 digits.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({ otp: "" });

    try {
      const res = await updatePhoneverify({ countryCode, mobile, otp });
      if (res) {
        handleUser(res?.data);
        toast.success("Mobile number verified successfully!");
        handleClose();
        setOtp("");
        setCountryCode("");
        setMobile("");
        setIsOtpSent("");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = await updatePhoneOtpsend({ countryCode, mobile });
      if (res) {
        toast.success("OTP resent to your phone.");
      }
    } catch (error) {
      toast.error("Error resending OTP");
    }
  };

  useEffect(() => {
    setOtp("");
    setCountryCode("");
    setMobile("");
    setIsOtpSent("");
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify Mobile Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Country Code</label> &nbsp; &nbsp;
          <input
            type="text"
            name="countryCode"
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value.replace(/\D/g, ""))}
            disabled={isOtpSent}
          />
          {errors.countryCode && <p className="error">{errors.countryCode}</p>}
        </div>
        <div>
          <label>Mobile Number</label> &nbsp; &nbsp;
          <input
            type="text"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
            disabled={isOtpSent}
          />
          {errors.mobile && <p className="error">{errors.mobile}</p>}
        </div>
        {isOtpSent && (
          <div>
            <label>OTP</label> &nbsp; &nbsp;
            <input
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
            />
            {errors.otp && <p className="error">{errors.otp}</p>}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {!isOtpSent ? (
          <Button variant="primary" onClick={handleSendOtp}>
            Send OTP
          </Button>
        ) : (
          <>
            <Button variant="primary" onClick={handleVerifyOtp}>
              Verify
            </Button>
            <Button variant="secondary" onClick={handleResendOtp}>
              Resend OTP
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MobileVerificationModal;
