import React, { useEffect, useRef } from "react";

const Ticker = () => {
  const containerRef = useRef();

  useEffect(() => {
    const containerDiv = containerRef.current;

    // Clean up existing scripts if any
    while (containerDiv.firstChild) {
      containerDiv.removeChild(containerDiv.firstChild);
    }

    // Create the script element
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        // {
        //   proName: "FOREXCOM:SPXUSD",
        //   title: "S&P 500 Index",
        // },
        {
          proName: "FOREXCOM:NSXUSD",
          title: "US 100 Cash CFD",
        },
        {
          proName: "FX_IDC:EURUSD",
          title: "EUR to USD",
        },
        {
          proName: "BITSTAMP:BTCUSD",
          title: "Bitcoin",
        },
        {
          proName: "BITSTAMP:ETHUSD",
          title: "Ethereum",
        },
        {
          proName: "BINANCE:BNBUSDT",
          title: "Binance Coin",
        },
        {
          proName: "BINANCE:ADAUSDT",
          title: "Cardano",
        },
        {
          proName: "BINANCE:XRPUSDT",
          title: "Ripple",
        },
        {
          proName: "BINANCE:DOGEUSDT",
          title: "Dogecoin",
        },
        {
          proName: "BINANCE:DOTUSDT",
          title: "Polkadot",
        },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "adaptive",
      colorTheme: "light",
      locale: "en",
    });
    // containerDiv.appendChild(script);
    setTimeout(() => {
        if (containerDiv) {
          containerDiv.appendChild(script);
        }
      }, 100);

    // Cleanup function to run when the component unmounts
    return () => {
      while (containerDiv.firstChild) {
        containerDiv.removeChild(containerDiv.firstChild);
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default Ticker;
