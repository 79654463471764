import axios  from "axios";
import { baseUrl, headers,  jwtHeaders } from "../common/data";


export const getFundData = async(page)=>{
    let url = `https://api.chainbroker.io/api/v1/funds/list`
    if(page){
        url = url+`?page=${page}`
    }
    try {
        const res = await axios.get(url)
        if(res.status==200){
            return res?.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAirDropData = async(status)=>{
    let url = `${baseUrl}/data/airdrop`
    if(status){
        url = url+`?status=${status}`
    }
    try {
        const res = await axios.get(url)
        if(res?.status==200){
            return res?.data
        }
    } catch (error) {
        
    }
}