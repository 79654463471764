import React, { useState } from 'react'
import coin from "../../Assets/Bitcoin.svg";
import { useNavigate } from 'react-router-dom';

const Explore = () => {
  const navigate= useNavigate()
    const [activeTab2, setActiveTab2] = useState(1);
    const handleTabChange2 = (tabIndex) => {
      setActiveTab2(tabIndex);
    };
  
  return (
    <div className="icos">
        <div className="icos-left">
          <h1>
            Explore crypto like Bitcoin,
            <br /> Ethereum, and Dogecoin
          </h1>
          <p>
            Simply and securely buy, sell,
            <br /> and manage hundreds of cryptocurrencies.
          </p>
          <br />
          <button>See More Assets</button>
        </div>
        <div className="icos-right">
          <div className="home-tab">
            <button
              className={activeTab2 === 1 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange2(1)}
            >
              Top Fund
            </button>
            <button
              className={activeTab2 === 2 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange2(2)}
            >
              Top Airdrops
            </button>
            <button
              className={activeTab2 === 3 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange2(3)}
            >
              Top Stack
            </button>
          </div>
          <br />
          <div className="tab-content">
            {activeTab2 === 1 && (
              <div className="icon-cards" onClick={()=>navigate("/Funds")}>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
              </div>
            )}
            {activeTab2 === 2 && (
              <div className="icon-cards" onClick={()=>navigate("/airdrop")}>
              <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
              </div>
            )}
            {activeTab2 === 3 && (
              <div className="icon-cards" onClick={()=>navigate("/Stack")}>
              <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
             <div>
             <div className="icon-card">
                  <img src={coin} />

                  <h1>BTC/UTC</h1>
                  <h3>32145 $</h3>
                  <h3>32145 $</h3>
                </div>
             </div>
              </div>
            )}
          </div>
        </div>
      </div>
  )
}

export default Explore
