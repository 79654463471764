import axios  from "axios";
import { baseUrl, headers,  jwtHeaders } from "../common/data";


export const getCoinData = async()=>{
    try {
        const res = await axios.get(`${baseUrl}/data/coin`)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}


export const getAirDrop = async()=>{
    try {
        const res = await axios.get(`${baseUrl}/data/airdrop`)
        if(res?.status==200){
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}