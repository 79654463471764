import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import io from 'socket.io-client';
import { useMain } from '../hooks/useMain';
import { ethBaseUrl, optionBaseUrl } from "./binanceurl";
import { toast } from "react-toastify";

const OptionWebSocketContext = createContext()
export const useOptionWebSocketContext = () => useContext(OptionWebSocketContext)


export const OptionWebSocketProvider = ({ children }) => {
    const { setOptionOrder } = useMain()
    const [connected, setConnected] = useState(false);
    const [orders, setOrders] = useState([])
    const [positionData, setPositionData]= useState([])
    const socket = useRef(null);
    const reconnectInterval = useRef(null);
    const isConnecting = useRef(false);
    const url = ethBaseUrl;
    useEffect(() => {
        const token = sessionStorage.getItem("user_token");

        if (!token) {
            console.error("No token found in sessionStorage");
            return;
        }

        const connectWebSocket = () => {
            if (socket.current && socket.current.connected) {
                // If already connected, no need to reconnect
                return;
            }

            if (isConnecting.current) {
                // If connection is in progress, don't start another one
                return;
            }
            isConnecting.current = true;
            socket.current = io(url, {
		transports:['websocket'],
                query: {
                    token: token,
		    path: 'option'
                }
            });

            socket.current.on('connect', () => {
                console.log('WebSocket connected');
                setConnected(true);
                if (reconnectInterval.current) {
                    clearInterval(reconnectInterval.current);
                    reconnectInterval.current = null;
                }
            });

            socket.current.on('disconnect', () => {
                console.log('WebSocket disconnected');
                setConnected(false);
                // Attempt to reconnect
                if (!reconnectInterval.current) {
                    reconnectInterval.current = setInterval(() => {
                        connectWebSocket();
                    }, 5000);
                }
            });

            socket.current.on('allOrders', (data) => {
                if (data.status === 'success') {
                    console.log(data);
                    setOrders(data.orders);
                    setOptionOrder(data.orders);
                } else {
                    console.error('Error fetching orders:', data.error);
                }
            });

            socket.current.on('newOrder', (data) => {
                if (data.status === 'success') {
                    // console.log(data.orders);
                    // setOrders(data.orders);
                } else {
                    console.error('Error fetching orders:', data.error);
                }
            });

            socket.current.on('allpositionResponse', (data) => {
                if (data.status === 'success') {
                    // console.log(data.position);
                    setPositionData(data.position);
                } else {
                    console.error('Error fetching orders:', data.error);
                }
            });

            socket.current.on('orderUpdate', (data) => {
                const orderId = data.order?._id;
                console.log('Order update:', data);

                if (data.status === 'Order placed') {
                    toast.success("Order Placed Done")
                    setOrders(prevOrders => [...prevOrders, data.order]);
                    setOptionOrder(prevOrders => [...prevOrders, data.order]);
                } else if (data.status == "cancel" || data.status == "update" || data.status == "squaredOff") {
                    if (data.status == "squaredOff") {
                        toast.success("Squateoff Order Placed.")
                    } else {
                        toast.success(`order ${data.status}`)
                    }
                    if(!data.order){
                        return
                    }
                    const updateOrders = (prevOrders) => {
                        const orderExists = prevOrders.some(order => order._id === orderId);
                        if (orderExists) {
                            return prevOrders.map(order =>
                                order._id === orderId ? { ...order, ...data.order } : order
                            );
                        }
                        return [...prevOrders, data.order];
                    };

                    setOrders(updateOrders);
                    setOptionOrder(updateOrders);
                } else if (data.status == "executed") {
                    toast.success(`order ${data.status}`)
                    if(!data.order){
                        return
                    }
                    const updateOrders = (prevOrders) => {
                        const orderExists = prevOrders.some(order => order._id === orderId);
                        if (orderExists) {
                            return prevOrders.map(order =>
                                order._id === orderId ? { ...order, ...data.order } : order
                            );
                        }
                        return [...prevOrders, data.order];
                    };
                    setOrders(updateOrders);
                    setOptionOrder(updateOrders);

                    socket.current.emit("allposition", {usertoken:sessionStorage.getItem("user_token")});
                }
            });

            socket.current.on('error', (error) => {
                console.error('WebSocket error:', error);
            });
        };

        connectWebSocket();

        return () => {
            if (socket.current) {
                socket.current.close();
            }
            if (reconnectInterval.current) {
                clearInterval(reconnectInterval.current);
                reconnectInterval.current = null;
            }
            isConnecting.current = false;
        };
    }, [url]);

    const sendMessage = (event, data) => {
        if (socket.current && connected) {
            socket.current.emit(event, data);
        }
    };

    return (
        <OptionWebSocketContext.Provider value={{ connected, orders, sendMessage, positionData }}>
            {children}
        </OptionWebSocketContext.Provider>
    );

}
