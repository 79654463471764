import React, { useState, useEffect } from "react";
import { useMain } from "../../hooks/useMain";

const SocialMediaLinks = () => {
  const { userData, updateUserData, handleUser } = useMain();
  const [formData, setFormData] = useState({
    linkedInProfile: "",
    twitterHandle: "",
    facebookProfile: "",
    instagramHandle: "",
    personalWebsiteBlog: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData(userData?.socialMediaLinks);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      const res = await updateUserData({ socialMediaLinks: formData });
      if (res) {
        handleUser(res.data);
      }
    } catch (error) {
      console.error("Error updating social media links:", error);
    }
  };

  return (
    <div className="tab-content-div">
      <div className="tab-content-innerdiv">
        <div className="tab-content-heading">
          <h3>Social Media Links</h3>
          <p>Links to your social media profiles.</p>
        </div>
        <div className="tab-content-inputs">
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>LinkedIn Profile</label>
              <input name="linkedInProfile" value={formData?.linkedInProfile} onChange={handleChange} />
            </div>
            <div className="tab-content-inputs-col">
              <label>Twitter Handle</label>
              <input name="twitterHandle" value={formData?.twitterHandle} onChange={handleChange} />
            </div>
            <div className="tab-content-inputs-col">
              <label>Facebook Profile</label>
              <input name="facebookProfile" value={formData?.facebookProfile} onChange={handleChange} />
            </div>
          </div>
          <div className="tab-content-inputs-row">
            <div className="tab-content-inputs-col">
              <label>Instagram Handle</label>
              <input name="instagramHandle" value={formData?.instagramHandle} onChange={handleChange} />
            </div>
            <div className="tab-content-inputs-col">
              <label>Personal Website/Blog</label>
              <input
                name="personalWebsiteBlog"
                value={formData?.personalWebsiteBlog}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="savechngs" onClick={handleSubmit}>Save Changes</button>
          <br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaLinks;
